import React, { useMemo, useState } from 'react';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InnerPage } from './InnerPage';
import { MenuListItem } from '../components/nav/menu';
import { PATHS } from '../paths';
import { ServiceProviderInnerPage } from './ServiceProviderInnerPage';
import { useLoginAs } from './useLoginAs';
import { Permissions } from '../api/permissions/permissions';

export const ServiceProviderPage: React.FC = ({ children }) => {
  const { loginAs } = useLoginAs();
  const [invitesCount, setInvitesCount] = useState(0);

  const primaryNav: MenuListItem[] = useMemo(() => [
    {
      id: 'sp-bid-center',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="megaphone" />
        </SvgIcon>
      ),
      text: 'Bid Center',
      url: PATHS.sp.bundles,
      badge: invitesCount > 0 ? {
        number: invitesCount,
        label: `${invitesCount} bid invites`,
      } : undefined,
    },
    {
      id: 'sp-chat',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="comment-alt-lines" />
        </SvgIcon>
      ),
      text: 'Messages',
      url: PATHS.sp.chat,
      disabled: loginAs !== null,
      title: loginAs !== null
        ? 'You cannot message as yourself from another organization'
        : undefined,
      permission: Permissions.SEND_MESSAGE,
    },
  ], [invitesCount, loginAs]);

  return (
    <InnerPage navType="sp" primaryNav={primaryNav}>
      <ServiceProviderInnerPage setInvitesCount={setInvitesCount}>
        {children}
      </ServiceProviderInnerPage>
    </InnerPage>
  );
};
