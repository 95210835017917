import React, { useMemo, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Masonry } from 'masonic';
import { Close, Edit } from '@mui/icons-material';
import { SpProfile, SpProfile_publicOrganization_spProfile_files } from '../../../api/types/SpProfile';
import { SpProfileFileType } from '../../../api/types/globalTypes';
import { EditFilesDialog } from './editing/EditFilesDialog';
import { GalleryImage } from './elements/GalleryImage';

const useStyles = makeStyles(() => createStyles({
  gallery: {},
  image: {
    width: '100%',
  },
}));

export const useGallery = (data: SpProfile) => {
  const images = useMemo(() => {
    if (!data.publicOrganization.spProfile) {
      return [];
    }
    return data.publicOrganization.spProfile.files
      .filter((f) => f.type === SpProfileFileType.GALLERY)
      .sort((f1, f2) => (f1.rank ?? 0) - (f2.rank ?? 0));
  }, [data.publicOrganization.spProfile]);

  return {
    images,
    showGallery: images.length > 0,
  };
};

export interface ProfileGalleryProps {
  data: SpProfile;
  edit: boolean;
  small?: boolean;
}

export const ProfileGallery: React.FC<ProfileGalleryProps> = ({
  data,
  edit,
  small,
}) => {
  const classes = useStyles();

  const { images, showGallery } = useGallery(data);

  const masonryKey = useMemo(
    () => images.map((i) => i.file_id).sort((id1, id2) => id1 - id2).join(','),
    [images],
  );

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [image, setImage] = useState<SpProfile_publicOrganization_spProfile_files>();
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const onImageDialogClose = () => {
    setImageDialogOpen(false);
    setImage(undefined);
  };

  const imagesWithOnClick = useMemo(
    () => images.map((i) => ({
      ...i,
      onClick: () => {
        setImage(i);
        setImageDialogOpen(true);
      },
    })),
    [images],
  );

  if (!showGallery && !edit) {
    return null;
  }

  return (
    <>
      <Box className={classes.gallery} padding={small ? 0 : 3}>
        <Box marginBottom={2}>
          <Grid
            container
            direction="row"
            spacing={2}
            wrap="nowrap"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
          >
            {small ? null : (
              <Grid item>
                <Typography variant="h4" id="profile-gallery">
                  Gallery
                </Typography>
              </Grid>
            )}
            {edit && (
              <Button
                variant="outlined"
                size="small"
                startIcon={<Edit color="action" />}
                onClick={() => setEditDialogOpen(true)}
              >
                Edit
              </Button>
            )}
          </Grid>
        </Box>
        <Masonry
          key={masonryKey}
          items={imagesWithOnClick}
          columnGutter={small ? 8 : 16}
          columnWidth={small ? 120 : 200}
          overscanBy={5}
          render={GalleryImage}
          style={{ outline: 'none' }}
        />
      </Box>
      <Dialog
        open={imageDialogOpen && !!image}
        onClose={onImageDialogClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <img
            src={image?.url}
            alt={image?.name ?? `Image #${image?.file_id}`}
            className={classes.image}
          />
        </DialogContent>
        <DialogActions>
          <Typography variant="body2" color="textSecondary">
            {image?.name ?? `Image #${image?.file_id}`}
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={onImageDialogClose}
            startIcon={<Close />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <EditFilesDialog
        data={data}
        type={SpProfileFileType.GALLERY}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      />
    </>
  );
};
