import React, { memo, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid } from '@mui/material';

import { FileUploadContext, Order, WhereOrganizationFileInput } from '../../../api/types/globalTypes';
import { Files, Files__organizationFiles_items, FilesVariables } from '../../../api/types/Files';
import { ADMIN_FILES, FILES } from '../queries/files';
import { ErrorMessage } from '../../notifications/ErrorMessage';
import { canModifyFile } from '../permissions';
import { useProfile } from '../../../api/user/profile';
import { FileBadge, FilesListItem } from './FilesListItem';
import { FileEditDialog } from './FileEditDialog';
import { SuccessSnackbar, useSuccessMessage } from '../../notifications/SuccessSnackbar';
import { ErrorSnackbar, useErrorSnackbar } from '../../notifications/ErrorSnackbar';
import { FileImagePreview } from './FileImagePreview';
import { whereByContext } from '../utils';
import { useFileDeleteHandler } from './useFileDeleteHandler';
import { FilesLoadingSkeleton } from './FilesLoadingSkeleton';
import { NoData } from '../../text/NoData';
import { UserRole } from '../../../pages/RoleAwareRedirect';
import { AdminFiles } from '../../../api/types/AdminFiles';

export interface FilesListProps {
  small?: boolean;
  showCategory?: boolean;
  itemWidth?: number;
  itemHeight?: number;
  showDateAdded?: boolean;
  emptyData?: string | JSX.Element;
  selectable?: boolean;
  editable?: boolean;
  selectedIds?: Set<number>,
  onSelectFile?: (file: Files__organizationFiles_items, checked: boolean) => void;
  /**
   * apply file list filter by context
   */
  context?: FileUploadContext;

  /**
   * apply extra query condition to files query
   */
  queryCondition?: WhereOrganizationFileInput;
  getFileBadges?: (file: Files__organizationFiles_items) => FileBadge[];

  viewAsRole?: UserRole;
}

export const FilesList = memo<FilesListProps>(({
  small,
  showCategory: extShowCategory,
  showDateAdded = true,
  editable = false,
  selectable = false,
  selectedIds,
  onSelectFile,
  getFileBadges,
  queryCondition,
  context,
  itemWidth,
  itemHeight,
  emptyData = 'No uploaded files yet',
  viewAsRole,
}) => {
  const profile = useProfile();
  const [fileToEdit, setFileToEdit] = useState<Files__organizationFiles_items | null>(null);
  const [previewFile, setPreviewFile] = useState<Files__organizationFiles_items | null>(null);
  const { successMessage, setSuccessMessage, ...successSnackbarHandlers } = useSuccessMessage();

  const where = useMemo<WhereOrganizationFileInput>(() => {
    if (!queryCondition && !context) return {};
    return {
      and: [
        queryCondition,
        context && whereByContext(context),
      ].filter((cond) => !!cond),
    } as WhereOrganizationFileInput;
  }, [queryCondition, context]);

  const {
    data: filesData, loading: filesLoading, error: filesError,
  } = useQuery<Files | AdminFiles, FilesVariables>(viewAsRole === 'admin' ? ADMIN_FILES : FILES, {
    variables: {
      where,
      order: [{ field: 'file_id', order: Order.DESC }],
      take: 999,
    },
    fetchPolicy: 'no-cache',
  });

  const { handleDelete, error: deleteError } = useFileDeleteHandler();
  const fileDeleteErrorSnackbar = useErrorSnackbar(deleteError);

  const handleDeleteFile = async (file: Files__organizationFiles_items) => {
    if (await handleDelete(file)) {
      setSuccessMessage(`${file.name} has been successfully deleted`);
    }
  };

  const fileItems = useMemo(() => {
    if (filesData && '_organizationFiles' in filesData) return filesData._organizationFiles.items;
    if (filesData && 'adminOrganizationFiles' in filesData) return filesData.adminOrganizationFiles.items;
    return [];
  }, [filesData]);

  if (filesError) {
    return (
      <ErrorMessage devMessage={filesError.message}>
        Failed loading a list of files
      </ErrorMessage>
    );
  }

  if (filesLoading) {
    return <FilesLoadingSkeleton width={itemWidth} height={itemHeight} itemsCount={8} />;
  }

  if (!filesLoading && !fileItems.length) {
    return typeof emptyData === 'string'
      ? <NoData>{emptyData}</NoData>
      : emptyData;
  }

  const showCategory = typeof extShowCategory === 'undefined' ? true : extShowCategory;

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      wrap="wrap"
      justifyContent="flex-start"
      alignItems="center"
      alignContent="center"
      columns={small ? [1, 1, 1] : undefined}
    >
      {fileItems.map((file) => {
        const canModify = !selectable && !!editable && canModifyFile(profile, file);
        return (
          <Grid key={file.file_id} item>
            <FilesListItem
              small={small}
              showCategory={showCategory}
              badges={getFileBadges ? getFileBadges(file) : undefined}
              selectable={selectable}
              selected={selectedIds?.has(file.file_id)}
              onSelectChange={(checked) => onSelectFile?.(file, checked)}
              file={file}
              editable={canModify}
              deletable={canModify}
              onDelete={() => handleDeleteFile(file)}
              onEdit={() => setFileToEdit(file)}
              onImagePreviewClick={() => setPreviewFile(file)}
              width={itemWidth}
              height={itemHeight}
              showDateAdded={showDateAdded}
            />
          </Grid>
        );
      })}
      {editable && (
        <FileEditDialog file={fileToEdit} onClose={() => setFileToEdit(null)} open={!!fileToEdit} />
      )}
      <FileImagePreview file={previewFile} onClose={() => setPreviewFile(null)} />
      <SuccessSnackbar {...successSnackbarHandlers}>
        {successMessage}
      </SuccessSnackbar>
      <ErrorSnackbar {...fileDeleteErrorSnackbar}>
        Unable to delete file
      </ErrorSnackbar>
    </Grid>
  );
});
