import React, { useEffect, useState, Suspense } from 'react';
import { useTheme } from '@mui/styles';
import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  useMediaQuery,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory, generatePath } from 'react-router-dom';
import { isEmpty, trim } from 'lodash';
import { setSmartDrawerOpen } from 'utils/settings';
import { TitleProps } from 'components/layout/Title';
import { ErrorPageMessage } from 'components/notifications/ErrorPageMessage';
import { APP_BAR_HEIGHT, SignedInAppBar } from 'components/nav/SignedInAppBar';
import { SmartDrawer } from 'components/nav/SmartDrawer';
import { MenuListItem, MenuListItemOrDivider } from 'components/nav/menu';
import { UserEmailNotVerifiedBanner } from 'components/user/UserEmailNotVerifiedBanner';
import { Profile_profile_organization_services } from 'api/types/Profile';
import { PROFILE_QUERY, ProfileContext } from 'api/user/profile';
import { ErrorMessage } from 'components/notifications/ErrorMessage';
import { Loading } from 'components/Loading';
import { signOut } from 'utils/auth';
import { PATHS } from 'paths';
import { LatestEula } from 'components/auth/LatestEula';
import { NavType } from 'components/nav/navType';
import { SetUserExtra, SetUserExtraVariables } from 'api/types/SetUserExtra';
import { SET_USER_EXTRA } from 'api/user/setUserExtra';
import { DialogProvider } from 'components/Dialog';
import { HeaderTitleContext } from 'components/nav/headerTitle';
import { ChatProvider } from 'components/chat/ChatProvider';
import useMapsAPI from 'components/map/utils/useMapsAPI';
import { checkPathType } from 'utils/url';
import { isVerifiedEmail } from 'components/user/isVerifiedEmail';
import { useLoginAs } from './useLoginAs';
import { useProfileWithAuth } from './useProfileWithAuth';
import { ChatSidebarProvider } from '../components/chat/ChatSidebar/ChatSidebarProvider';
import { IS_QBO_INTEGRATION_LIVE } from './adminQueries/isQBOIntegrationLiveQuery';

export const NAVIGATION_MENU_WIDTH = 36;

export interface InnerPageProps {
  navType: NavType;
  primaryNav: MenuListItem[];
  secondaryNav?: MenuListItem[]; // For SP/SM, leave empty - will show service menu
  settingsNav?: MenuListItem[]; // Leave empty for standard menu: user profile menu, settings, help
}

function getSPSecondaryMenu(
  services: Profile_profile_organization_services[],
  secondaryNav?: MenuListItem[],
): MenuListItemOrDivider[] {
  const serviceMenu: MenuListItem[] = services.map(({
    service_id, name, slug, icon,
  }) => {
    const slugOrId = isEmpty(trim(slug)) ? service_id : trim(slug);
    return {
      id: `sp-organization-service-${slugOrId}`,
      type: '_menu_item_url',
      url: generatePath(PATHS.sp.organizationService, { slug: slugOrId }),
      text: name,
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon={icon as IconProp} />
        </SvgIcon>
      ),
    };
  });
  return [
    {
      id: 'profiles-section-title',
      type: '_menu_item_text',
      text: 'Profiles',
    },
    {
      id: 'sp-organization-info',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="building" />
        </SvgIcon>
      ),
      text: 'Organization Profile',
      url: PATHS.sp.organizationInfo.main,
      pathMatchPrefix: PATHS.sp.organizationInfo.main,
    },
    ...(secondaryNav ? [...secondaryNav, 'divider'] as MenuListItemOrDivider[] : []),
    ...serviceMenu,
  ];
}

export const InnerPage: React.FC<InnerPageProps> = ({
  navType,
  primaryNav,
  secondaryNav,
  settingsNav,
  children,
}) => {
  const theme = useTheme();
  const { removeLoginAs } = useLoginAs();
  const { mapsApiLoaded } = useMapsAPI();
  const drawerWidthOpen = parseInt(theme.spacing(NAVIGATION_MENU_WIDTH), 10);
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = upMd;
  const isMobile = !upMd;

  const history = useHistory();

  const [profileMenuAnchor, setProfileMenuAnchor] = useState<Element>();
  const closeProfileMenu = () => setProfileMenuAnchor(undefined);

  const [switchServiceMenuAnchor, setSwitchServiceMenuAnchor] = useState<Element>();
  const closeSwitchServiceMenu = () => setSwitchServiceMenuAnchor(undefined);

  const [headerTitle, setHeaderTitle] = useState<TitleProps>();

  const {
    profileLoading,
    profileData,
    profileError,
    firebaseAuth,
  } = useProfileWithAuth(history);

  useEffect(() => {
    const { isAdminPath } = checkPathType(history.location.pathname);

    if (isAdminPath) {
      removeLoginAs();

      // Redirect non-admins away from admin UI
      if (profileData && !profileData.profile.is_system_admin) {
        history.push(PATHS.home);
      }
    }
  }, [history, removeLoginAs, profileData]);

  const settingsMenu: MenuListItem[] = settingsNav || [
    {
      id: 'user-account',
      type: '_menu_item_onclick',
      icon: firebaseAuth.user && firebaseAuth.user.photoURL ? (
        <Avatar alt="user menu" src={firebaseAuth.user.photoURL} />
      ) : (
        <SvgIcon>
          <FontAwesomeIcon icon="user-cog" />
        </SvgIcon>
      ),
      text: 'Your Account',
      onClick: (e) => setProfileMenuAnchor(e.currentTarget),
    },
  ];

  const [
    setUserExtra,
    { loading: setUserExtraLoading, error: setUserExtraError },
  ] = useMutation<SetUserExtra, SetUserExtraVariables>(SET_USER_EXTRA, {
    refetchQueries: [PROFILE_QUERY],
  });

  /**
   * this sends request to our api for QBO integration check,
   * and refreshes tokens in case near expiration
   * response not used yet, but query is necessary even without response handling
   */
  const [isQboLive] = useLazyQuery(IS_QBO_INTEGRATION_LIVE, { fetchPolicy: 'no-cache' });
  useEffect(() => {
    if (profileData && profileData?.profile.is_system_admin) {
      isQboLive();
    }
  }, [isQboLive, profileData]);

  if (
    profileLoading
    || setUserExtraLoading
    || firebaseAuth.loggedIn === undefined
    || !mapsApiLoaded
  ) {
    return <Loading center fullScreen />;
  }

  if (profileError || setUserExtraError) {
    return (
      <ErrorMessage
        fullScreen
        center
        devMessage={profileError?.message || setUserExtraError?.message}
      >
        Failed loading profile data
      </ErrorMessage>
    );
  }

  if (!profileData) {
    return (
      <ErrorMessage fullScreen center devMessage="data object is not set">
        Failed loading profile data
      </ErrorMessage>
    );
  }

  const { profile } = profileData;
  const { organization } = profile;

  if (!organization) {
    return (
      <ErrorMessage fullScreen center devMessage="Organization is not set in profile data">
        No organization set for the current user
      </ErrorMessage>
    );
  }

  const lastServiceId = profile.extra.last_service_id;
  const lastService = lastServiceId
    ? organization.services.find((s) => s.service_id === lastServiceId)
    : organization.services[0];

  const fmServiceSwitch: MenuListItem = {
    id: 'fm-service-switch',
    type: '_menu_item_onclick',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon={(lastService?.icon || 'question') as IconProp} />
      </SvgIcon>
    ),
    iconRight: <ChevronRight />,
    text: lastService?.name || 'No service',
    secondaryText: 'Switch service',
    onClick: (e) => setSwitchServiceMenuAnchor(e.currentTarget),
  };

  const secondaryMenu = navType === 'sp'
    ? getSPSecondaryMenu(organization.services, secondaryNav)
    : (secondaryNav || []);
  const navCombined: MenuListItemOrDivider[] = [
    ...(navType === 'fm' ? [fmServiceSwitch] : []),
    ...(isDesktop ? [...primaryNav, 'divider'] as MenuListItemOrDivider[] : []),
    ...secondaryMenu,
    ...(isMobile ? ['divider', ...settingsMenu] as MenuListItemOrDivider[] : []),
  ];

  const fullName = `${profile.first_name} ${profile.last_name}`;
  const isSP = organization.is_service_provider;
  const isFM = organization.is_facility_manager;
  const isAdmin = profile.is_system_admin;

  if (
    (navType === 'sp' && !isSP)
    || (navType === 'fm' && !isFM)
    || (navType === 'admin' && !isAdmin)
  ) {
    return <ErrorPageMessage errorCode="403" message="Forbidden" />;
  }

  return (
    <HeaderTitleContext.Provider value={setHeaderTitle}>
      <ProfileContext.Provider value={profileData}>
        <ChatProvider>
          <SmartDrawer
            navType={navType}
            widthOpen={drawerWidthOpen}
            appBar={(className) => (
              <SignedInAppBar
                navType={navType}
                className={className}
                menu={settingsMenu}
                titleProps={headerTitle}
              />
            )}
            menu={navCombined}
            mobileMenu={primaryNav}
            desktop={upMd}
            fmServiceSlug={lastService?.slug}
          >
            <DialogProvider>
              <ChatSidebarProvider>
                <Box mt={APP_BAR_HEIGHT} padding={2}>
                  <LatestEula />
                  {!isVerifiedEmail(profileData.profile) && (
                    <UserEmailNotVerifiedBanner />
                  )}
                  <Suspense fallback={<Loading center />}>
                    {children}
                  </Suspense>
                </Box>
              </ChatSidebarProvider>
            </DialogProvider>
          </SmartDrawer>
          <Menu
            id="fm-switch-service-menu"
            anchorEl={switchServiceMenuAnchor}
            open={Boolean(switchServiceMenuAnchor)}
            onClose={closeSwitchServiceMenu}
          >
            {organization.services.map(({ service_id, name }) => (
              <MenuItem
                key={service_id}
                onClick={() => {
                  setUserExtra({
                    variables: {
                      userId: profile.user_id,
                      userExtra: {
                        last_service_id: service_id,
                      },
                    },
                  });
                  // Close current submenu popup
                  closeSwitchServiceMenu();
                  // If is mobile menu, close menu on menu item click
                  if (isMobile) setSmartDrawerOpen(false);

                  // Goto link
                  const url = history.location.pathname;
                  let redirectTo;

                  if (url.includes(PATHS.fm.organizationSites)) {
                    redirectTo = PATHS.fm.organizationSites;
                  } else if (url.includes(PATHS.fm.organizationAllBids)) {
                    redirectTo = PATHS.fm.organizationAllBids;
                  } else if (url.includes(PATHS.fm.organizationSPSites)) {
                    redirectTo = PATHS.fm.organizationSPSites;
                  } else if (url.includes(PATHS.fm.organizationBundles) || url.includes('/fm/bids')) {
                    redirectTo = PATHS.fm.organizationBundles;
                  } else if (url.includes(PATHS.fm.organizationContractTerms)) {
                    redirectTo = PATHS.fm.organizationContractTerms;
                  } else if (url.includes(PATHS.fm.messages)) {
                    redirectTo = PATHS.fm.messages;
                  } else if (url.includes(PATHS.fm.chat)) {
                    redirectTo = PATHS.fm.chat;
                  }

                  if (redirectTo) {
                    history.push(redirectTo);
                  }
                }}
              >
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Menu>
          <Menu
            id="user-profile-menu"
            anchorEl={profileMenuAnchor}
            keepMounted
            open={Boolean(profileMenuAnchor)}
            onClose={closeProfileMenu}
          >
            <MenuItem
              onClick={() => {
                closeProfileMenu();
                if (navType === 'admin') {
                  history.push(PATHS.admin.userProfile);
                } else {
                  history.push(navType === 'sp' ? PATHS.sp.userProfile : PATHS.fm.userProfile);
                }
              }}
            >
              {firebaseAuth.user?.photoURL ? (
                <ListItemAvatar>
                  <Avatar alt={fullName} src={firebaseAuth.user.photoURL} />
                </ListItemAvatar>
              ) : (
                <ListItemIcon>
                  <SvgIcon>
                    <FontAwesomeIcon icon="user" />
                  </SvgIcon>
                </ListItemIcon>
              )}
              <ListItemText primary={<strong>{fullName}</strong>} />
            </MenuItem>
            {isAdmin && navType !== 'admin' && (
              <MenuItem
                onClick={() => {
                  closeProfileMenu();
                  removeLoginAs();
                  history.push(PATHS.admin.main);
                }}
              >
                <ListItemIcon>
                  <SvgIcon>
                    <FontAwesomeIcon icon="exchange" />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary="Switch to Admin" />
              </MenuItem>
            )}
            {isSP && navType !== 'sp' && (
              <MenuItem
                onClick={() => {
                  closeProfileMenu();
                  history.push(PATHS.sp.main);
                }}
              >
                <ListItemIcon>
                  <SvgIcon>
                    <FontAwesomeIcon icon="exchange" />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary="Switch to SP" />
              </MenuItem>
            )}
            {isFM && navType !== 'fm' && (
              <MenuItem
                onClick={() => {
                  closeProfileMenu();
                  history.push(PATHS.fm.main);
                }}
              >
                <ListItemIcon>
                  <SvgIcon>
                    <FontAwesomeIcon icon="exchange" />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary="Switch to FM" />
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                closeProfileMenu();
                removeLoginAs();
                signOut();
                history.push(PATHS.home);
              }}
            >
              <ListItemIcon>
                <SvgIcon>
                  <FontAwesomeIcon icon="sign-out" />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </ChatProvider>
      </ProfileContext.Provider>
    </HeaderTitleContext.Provider>
  );
};
