import { FieldValues } from 'react-hook-form';
import React from 'react';
import {
  Grid, GridProps, GridSize,
} from '@mui/material';
import { CustomFieldSelect } from './CustomFieldTypes/CustomFieldSelect';
import { getFieldName } from './helpers';
import { CustomFieldWYSWIG } from './CustomFieldTypes/CustomFieldWYSWIG';
import { CustomFieldCheckbox } from './CustomFieldTypes/CustomFieldCheckbox';
import { CustomFieldNumber } from './CustomFieldTypes/CustomFieldNumber';
import { CustomFieldMultiCheckbox } from './CustomFieldTypes/CustomFieldMultiCheckbox';
import { CustomFieldMultiSelect } from './CustomFieldTypes/CustomFieldMultiSelect';
import { CustomFieldLabel } from './CustomFieldTypes/CustomFieldLabel';
import { CustomFieldHTML } from './CustomFieldTypes/CustomFieldHTML';
import { CustomFieldText } from './CustomFieldTypes/CustomFieldText';
import { FieldType } from '../../api/customFields';
import { CustomFieldFormColumns, CustomFieldType } from './types';

interface CustomFieldRenderProps {
  field: CustomFieldType;
  disabled: boolean;
  fieldValues: FieldValues;
  onChange: (field: CustomFieldType, value: any) => void;
  columns: CustomFieldFormColumns;
}

export const CustomFieldFormRender: React.FC<CustomFieldRenderProps> = ({
  field,
  disabled,
  fieldValues,
  onChange,
  columns,
}) => {
  const fieldProps1 = {
    field,
    disabled,
    value: fieldValues[getFieldName(field)],
    onChange: (e: any) => onChange(field, e),
  };

  const fieldProps2 = {
    ...fieldProps1,
    onChange: (e: any) => onChange(field, e.target.value),
  };

  const gridProps1: Partial<GridProps> = {
    key: field.field_id,
    item: true,
    xs: 12 as GridSize,
    md: 12 as GridSize,
  };

  const gridProps2: Partial<GridProps> = {
    ...gridProps1,
    md: (12 / columns) as GridSize,
    container: true,
    alignItems: 'center',
  };

  const gridProps3: Partial<GridProps> = {
    ...gridProps2,
    alignContent: 'flex-end',
    alignItems: 'flex-end',
  };

  const mapping = {
    [FieldType.select]: {
      gridProps: gridProps3,
      render: () => (<CustomFieldSelect {...fieldProps2} />),
    },
    [FieldType.wysiwig]: {
      gridProps: gridProps1,
      render: () => (<CustomFieldWYSWIG {...fieldProps1} />),
    },
    [FieldType.checkbox]: {
      gridProps: gridProps2,
      render: () => (<CustomFieldCheckbox {...fieldProps2} />),
    },
    [FieldType.number]: {
      gridProps: gridProps3,
      render: () => (<CustomFieldNumber {...fieldProps2} />),
    },
    [FieldType.multiCheckbox]: {
      gridProps: gridProps1,
      render: () => (<CustomFieldMultiCheckbox {...fieldProps1} />),
    },
    [FieldType.multiSelect]: {
      gridProps: gridProps1,
      render: () => (<CustomFieldMultiSelect {...fieldProps1} />),
    },
    [FieldType.label]: {
      gridProps: gridProps1,
      render: () => (<CustomFieldLabel key={field.field_id} field={field} />),
    },
    [FieldType.html]: {
      gridProps: gridProps1,
      render: () => (<CustomFieldHTML key={field.field_id} field={field} />),
    },
    [FieldType.text]: {
      gridProps: gridProps3,
      render: () => (<CustomFieldText {...fieldProps2} />),
    },
  };

  const selectedElement = mapping[field.type as FieldType];

  return (
    <Grid {...selectedElement.gridProps}>
      {selectedElement.render()}
    </Grid>
  );
};
