import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import { cloneDeep, get } from 'lodash';
import { ErrorMessage } from '../notifications/ErrorMessage';
import { SuccessSnackbar } from '../notifications/SuccessSnackbar';
import { getFieldName, getValidationParams } from './helpers';
import { CustomFieldFormHeader } from './CustomFieldFormHeader';
import { CustomFieldFormRender } from './CustomFieldFormRender';
import { CustomFormTypes } from './const';
import {
  CustomFieldFormColumns,
  CustomFieldFormOptions, CustomFieldType, CustomFieldTypes, CustomFieldValueTypes,
} from './types';
import { useCustomFieldRequest } from './requests';

/**
 * If you want to add new Entities with specific logic, look at types, requests, helpers
 */

export interface CustomFieldFormProps {
  targetOrganizationID: number;
  fieldArray: CustomFieldTypes;
  fieldValues: CustomFieldValueTypes;
  type: CustomFormTypes;
  formDisabled: boolean;
  options?: CustomFieldFormOptions;
  columns: CustomFieldFormColumns;
}

/**
 * Universal form for custom fields
 * @param props
 * @constructor
 */
export const CustomFieldForm: React.FC<CustomFieldFormProps> = ({
  targetOrganizationID,
  options,
  fieldArray,
  fieldValues,
  type,
  formDisabled,
  columns,
}) => {
  const [formFieldValues, setFormFieldValues] = useState<FieldValues>({});
  const [error, setError] = useState<string>();
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);

  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    triggerValidation,
  } = useForm();

  // Queries and mutations
  // ===============================================================================================
  const {
    update: updateRequest,
    error: requestError,
    loading: requestLoading,
  } = useCustomFieldRequest({
    type,
    targetOrganizationID,
    options,
    fieldArray,
    fieldValues: formFieldValues,
  });

  // Handlers and events
  // ===============================================================================================
  const setFieldToForm = useCallback((
    fieldName: string,
    value: any,
  ) => {
    setFormFieldValues((vs) => {
      const newVs = cloneDeep(vs);
      newVs[fieldName] = value;
      return newVs;
    });
    setValue(fieldName, value);
    triggerValidation(fieldName);
  }, [setValue, triggerValidation]);

  const closeSuccessMessage = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMessageOpen(false);
  };

  const handleFormSubmit = handleSubmit(async () => {
    if (formDisabled) {
      return;
    }

    try {
      await updateRequest();
      setSuccessMessageOpen(true);
    } catch (e: any) {
      setError(e.message ?? e.toString());
    }
  });

  const handleFieldSet = (field: CustomFieldType, value: any) => {
    const fieldName = getFieldName(field);
    setFieldToForm(fieldName, value);
  };

  // Effects
  // ===============================================================================================
  useEffect(() => {
    if (fieldArray.length > 0) {
      const fieldNames: string[] = [];
      fieldArray.forEach((field) => {
        const fieldServiceID = get(field, 'service_id');
        const optionsServiceID = get(options, 'serviceId');

        if (type === CustomFormTypes.FMSP
          || (type === CustomFormTypes.SERVICE && fieldServiceID === optionsServiceID)
        ) {
          const name = getFieldName(field);
          register(name, getValidationParams(field));
          fieldNames.push(name);
          const val = fieldValues
            .find((fv) => getFieldName(fv.field) === name)?.value;
          setValue(name, val);
        }
      });
      return () => unregister(fieldNames);
    }
    return () => { };
  }, [fieldArray, fieldValues, register, options, setValue, unregister, type]);

  useEffect(() => {
    if (fieldValues.length > 0) {
      fieldValues.forEach((value) => {
        setFieldToForm(getFieldName(value.field), value.value);
      });
    }
  }, [fieldValues, setFieldToForm]);

  return (
    <>
      <CustomFieldFormHeader
        // isDirty={isDirty}
        type={type}
        options={options}
        disabled={formDisabled}
      />
      <Grid
        container
        component="form"
        onSubmit={handleFormSubmit}
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {fieldArray.map((field) => CustomFieldFormRender({
              field,
              disabled: formDisabled,
              fieldValues: formFieldValues,
              onChange: handleFieldSet,
              columns,
            }))}
          </Grid>
        </Grid>
        {(error || requestError) && (
          <Grid item>
            <ErrorMessage devMessage={error || requestError?.message}>
              Failure on information update
            </ErrorMessage>
          </Grid>
        )}
        <Grid item>
          <Button
            type="submit"
            color="primary"
            disabled={requestLoading || formDisabled}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
      <SuccessSnackbar open={successMessageOpen} onClose={closeSuccessMessage}>
        Updated successfully
      </SuccessSnackbar>
    </>
  );
};
