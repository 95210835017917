/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BidComponentBulkMode {
  REJECT = "REJECT",
  UNDO = "UNDO",
}

export enum BidEnvelopeFileType {
  BidPrices = "BidPrices",
  File = "File",
}

export enum BulkActionStage {
  Execution = "Execution",
  PreValidation = "PreValidation",
}

/**
 * One of `forever`, `once`, and `repeating`. Describes how long a customer who applies this coupon will get the discount.
 */
export enum CouponDuration {
  FOREVER = "FOREVER",
  ONCE = "ONCE",
  REPEATING = "REPEATING",
}

export enum CreateUserTypeEnum {
  INVITED_USER = "INVITED_USER",
  PRE_APPROVED = "PRE_APPROVED",
  REQUEST_ACCESS = "REQUEST_ACCESS",
}

export enum DocumentDownloadType {
  CombinedPdf = "CombinedPdf",
  FullArchive = "FullArchive",
  Prices = "Prices",
}

export enum FieldType {
  CHECKBOX = "CHECKBOX",
  MULTI_CHECKBOX = "MULTI_CHECKBOX",
  MULTI_SELECT = "MULTI_SELECT",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
  TEXT = "TEXT",
  YES_NO = "YES_NO",
}

export enum FileType {
  DocuSignTemplate = "DocuSignTemplate",
  Storage = "Storage",
}

export enum FmSpStatPreferenceLevel {
  BlackList = "BlackList",
  Default = "Default",
  Favorite = "Favorite",
}

export enum ImportRecordAction {
  Inserted = "Inserted",
  Updated = "Updated",
}

export enum ImportRecordStatus {
  Error = "Error",
  InProgress = "InProgress",
  Queued = "Queued",
  Success = "Success",
  Warning = "Warning",
}

export enum ImportType {
  Ghosts = "Ghosts",
  Sites = "Sites",
}

export enum LayoutRowDirection {
  COLUMN = "COLUMN",
  ROW = "ROW",
}

/**
 * order direction
 */
export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SSQuestionType {
  CHECKBOX = "CHECKBOX",
  MULTI_CHECKBOX = "MULTI_CHECKBOX",
  MULTI_SELECT = "MULTI_SELECT",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
  TEXT = "TEXT",
}

export enum ServiceFieldLevel {
  OrganizationLevel = "OrganizationLevel",
  ServiceLevel = "ServiceLevel",
}

export enum SiteChangeLabelsMode {
  Append = "Append",
  Overwrite = "Overwrite",
}

export enum SiteMetadataVisible {
  All = "All",
  Fm = "Fm",
  Sp = "Sp",
}

export enum SpProfileFileType {
  ATTACHMENT = "ATTACHMENT",
  BANNER = "BANNER",
  BANNER_GALLERY = "BANNER_GALLERY",
  GALLERY = "GALLERY",
  LOGO = "LOGO",
}

export enum UserInviteFor {
  AcceptInvitation = "AcceptInvitation",
  GhostClaim = "GhostClaim",
}

export enum UserType {
  admin = "admin",
  fm = "fm",
  sp = "sp",
}

export interface AddWidgetInput {
  widget_id: number;
  column: number;
}

export interface AreaInput {
  region_id?: number | null;
  area_id?: number | null;
  state_code: string;
}

export interface BidDocumentRequestInput {
  file_category_id: number;
  note?: string | null;
}

export interface BidInviteInput {
  organization_id: number;
}

export interface BidReInviteInput {
  bid_id: number;
}

export interface BidScreenerAnswerInput {
  screener_field_id: number;
  answer: any;
}

export interface BidUpdateInput {
  status_id?: number | null;
  decline_reason_id?: number | null;
  reject_reason_id?: number | null;
  reject_note?: string | null;
  start_date?: any | null;
  end_date?: any | null;
  submit_comment?: string | null;
  screenerAnswers?: BidScreenerAnswerInput[] | null;
}

export interface BoundariesWhereInput {
  ne: any;
  sw: any;
  size: any;
}

export interface BundleBulkSettings {
  name: string;
  automatically_send: boolean;
}

export interface ContractTermCreateInput {
  organization_id: number;
  service_id?: number | null;
  name: string;
  start_date: any;
  end_date: any;
  client_name?: string | null;
}

export interface ContractTermUpdateInput {
  organization_id?: number | null;
  service_id?: number | null;
  name?: string | null;
  start_date?: any | null;
  end_date?: any | null;
  client_name?: string | null;
}

export interface CreateBundleInput {
  name: string;
  service_id: number;
  bids?: BidInviteInput[] | null;
}

export interface CreateRegionInput {
  name: string;
  areas: AreaInput[];
}

export interface CreateSiteInput {
  name?: string | null;
  status_id?: number | null;
  service_id: number;
  external_id?: string | null;
  store_number?: string | null;
  note?: string | null;
  service_contract_id: number;
  contract_type_id: number;
  owner_name: string;
  sow_file_id?: number | null;
  labels?: string[] | null;
  location_id?: number | null;
  location?: UpsertLocationInput | null;
}

export interface CreateSiteServiceInput {
  name: string;
  site_id: number;
  service_id: number;
  price_type: number;
  service_price_enabled: boolean;
  price?: number | null;
  per_unit_id?: number | null;
  start_date?: any | null;
  end_date?: any | null;
  questions?: SiteServiceQuestionInput[] | null;
  variations: UpdateSiteServiceVariation[];
}

export interface EnvelopeFileInput {
  type: BidEnvelopeFileType;
  file_id?: number | null;
  initialed?: boolean | null;
  rank: number;
}

export interface EnvelopeInput {
  sp_organization_id: number;
  service_id: number;
  sp_name: string;
  bid_ids: number[];
  files: EnvelopeFileInput[];
  recipients?: EnvelopeRecipientInput[] | null;
}

export interface EnvelopeRecipientInput {
  type: string;
  role: string;
  docusign_role: string;
  name: string;
  email: string;
  du_order: number;
}

export interface EnvelopeSignatoryChange {
  recipient_id: number;
  signatory_name: string;
  signatory_email: string;
}

export interface ExportField {
  title: string;
  field: any;
  args?: any | null;
}

export interface FieldColumnsInput {
  site_name?: string | null;
  owner_name?: string | null;
}

export interface FieldConfigurationInput {
  label: string;
  type: FieldType;
  is_required: boolean;
  min?: number | null;
  max?: number | null;
  options?: FieldOptionInput[] | null;
}

export interface FieldOptionInput {
  label?: string | null;
  value: any;
}

export interface FileUploadContext {
  service_id?: number | null;
  contract_term_id?: number | null;
  for_organization_id?: number | null;
  property_owner_id?: number | null;
  site_id?: number | null;
  bid_id?: number | null;
}

export interface FileUploadInput {
  from_file_id?: number | null;
  file?: any | null;
  docusign_template_id?: string | null;
  name: string;
  category_id: number;
  show_in_repository?: boolean | null;
  is_public?: boolean | null;
}

export interface FmSpFieldValueInput {
  field_id: number;
  value: any;
}

export interface ImportOptionsInput {
  sheet: number;
  header_row?: number | null;
  from_row?: number | null;
  to_row?: number | null;
  field_columns?: FieldColumnsInput | null;
}

export interface ManageBundleSiteInput {
  site_id: number;
}

export interface ManageBundleSitesInput {
  service_id: number;
  mode: string;
  sites: ManageBundleSiteInput[];
}

export interface ManageServiceAreaInputType {
  service_id: number;
  mode: string;
  select_by?: string | null;
  shape: any;
}

export interface OrderInput {
  field: string;
  order?: Order | null;
}

export interface OrganizationAddressInput {
  address_line_1: string;
  address_line_2?: string | null;
  city: string;
  state_code: string;
  country_code: string;
  postal_code: string;
  operation_hours?: string | null;
  google_place_id?: string | null;
  latlong?: any | null;
}

export interface OrganizationCreateInput {
  name: string;
  ein?: string | null;
  dba?: string | null;
  is_service_provider: boolean;
  is_facility_manager: boolean;
  services: OrganizationServiceInput[];
  website_url?: string | null;
  phone_number?: string | null;
  about?: string | null;
  help?: string | null;
  serviceFieldValues?: OrganizationServiceFieldValueInput[] | null;
  address?: OrganizationAddressInput | null;
  spProfile?: SpOrganizationProfileInput | null;
  fm?: number[] | null;
}

export interface OrganizationFileUpdateInput {
  name?: string | null;
  category_id?: number | null;
  is_public: boolean;
}

export interface OrganizationGhostCreate {
  name: string;
  ein?: string | null;
  dba?: string | null;
  phone_number?: string | null;
  website_url?: string | null;
  about?: string | null;
  help?: string | null;
  services: OrganizationServiceInput[];
  serviceFieldValues?: OrganizationServiceFieldValueInput[] | null;
  address?: OrganizationAddressInput | null;
  userInvites: UserInviteCreate[];
}

export interface OrganizationServiceFieldValueInput {
  field_id: number;
  service_id?: number | null;
  value: any;
}

export interface OrganizationServiceInput {
  service_id: number;
}

export interface OrganizationUpdateInput {
  name?: string | null;
  ein?: string | null;
  dba?: string | null;
  website_url?: string | null;
  phone_number?: string | null;
  about?: string | null;
  help?: string | null;
  default_signatory_name?: string | null;
  default_signatory_email?: string | null;
  services?: OrganizationServiceInput[] | null;
  serviceFieldValues?: OrganizationServiceFieldValueInput[] | null;
  address?: OrganizationAddressInput | null;
  spProfile?: SpOrganizationProfileInput | null;
  fm?: number[] | null;
  sp_bid_access?: number | null;
}

export interface PaymentDetails {
  plan_id: string;
  payment_method_id: string;
  coupon?: string | null;
}

export interface QBOInvoiceWhere {
  Balance?: WhereOperationsInput | null;
  TotalAmt?: WhereOperationsInput | null;
  DueDate?: WhereOperationsInput | null;
  TxnDate?: WhereOperationsInput | null;
  DocNumber?: WhereOperationsInput | null;
}

export interface RejectionInputType {
  rejection_note: string;
  other_info?: string | null;
}

export interface ResetServiceAreaItem {
  service_id: number;
  radius: number;
}

export interface SSQuestionOptionInput {
  label?: string | null;
  value: any;
}

export interface ScreenerFieldUpdateInput {
  screener_field_id?: number | null;
  rank: number;
  configuration: FieldConfigurationInput;
}

export interface ScreenerInput {
  name?: string | null;
  service_id?: number | null;
  is_enabled?: boolean | null;
  fields?: ScreenerFieldUpdateInput[] | null;
}

export interface SiteMetadataInput {
  datapoint_id?: number | null;
  name: string;
  value: string;
  visible_to: SiteMetadataVisible;
  group_name?: string | null;
  integration_id?: number | null;
}

export interface SiteServiceQuestionInput {
  question_id?: string | null;
  type: SSQuestionType;
  label: string;
  is_required: boolean;
  per_unit_id?: number | null;
  min?: number | null;
  max?: number | null;
  options?: SSQuestionOptionInput[] | null;
}

export interface SpOrganizationProfileInput {
  banner_title?: string | null;
  banner_subtitle?: string | null;
  years_in_business?: number | null;
  about?: string | null;
  phone_number?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  twitter?: string | null;
  linkedin?: string | null;
}

export interface UpdateBundleInput {
  name?: string | null;
  status_id?: number | null;
  screener_id?: number | null;
  invites?: BidInviteInput[] | null;
  start_date?: any | null;
  end_date?: any | null;
}

export interface UpdatePermissionInput {
  permission_id: number;
  region_id?: number | null;
}

export interface UpdateRegionInput {
  region_id: number;
  name: string;
  areas: AreaInput[];
}

export interface UpdateSiteInput {
  name?: string | null;
  status_id?: number | null;
  service_id: number;
  external_id?: string | null;
  store_number?: string | null;
  note?: string | null;
  service_contract_id: number;
  contract_type_id: number;
  owner_name: string;
  sow_file_id?: number | null;
  labels?: string[] | null;
  location_id?: number | null;
  location?: UpsertLocationInput | null;
}

export interface UpdateSiteServiceInput {
  name: string;
  site_id: number;
  service_id: number;
  price_type: number;
  service_price_enabled: boolean;
  price?: number | null;
  per_unit_id?: number | null;
  start_date?: any | null;
  end_date?: any | null;
  questions?: SiteServiceQuestionInput[] | null;
  variations: UpdateSiteServiceVariation[];
}

export interface UpdateSiteServiceVariation {
  variation_id?: number | null;
  name: string;
  price?: number | null;
  per_unit_id: number;
  rank?: number | null;
  questions?: SiteServiceQuestionInput[] | null;
}

export interface UpdateWidgetConfigurationInput {
  name?: string | null;
  column?: number | null;
  column_order?: number | null;
}

export interface UpdateWidgetInput {
  user_widget_id: number;
  configuration: UpdateWidgetConfigurationInput;
  arguments: any;
}

export interface UpsertBidComponentAnswer {
  question_id: string;
  value: any;
}

export interface UpsertBidComponentInput {
  bid_id: number;
  site_service_id: number;
  variation_id?: number | null;
  price?: number | null;
  note?: string | null;
  accepted?: boolean | null;
  answers?: UpsertBidComponentAnswer[] | null;
  fm_note?: string | null;
  fm_note_resolved_on?: any | null;
  include_in_award?: boolean | null;
}

export interface UpsertFmSpStatInput {
  preference_level?: FmSpStatPreferenceLevel | null;
  is_incumbent?: boolean | null;
}

export interface UpsertLocationInput {
  address_line_1?: string | null;
  address_line_2?: string | null;
  city?: string | null;
  postal_code: string;
  state_code: string;
  latlong: any;
}

export interface UpsertOrganizationContactInput {
  contact_id?: number | null;
  type_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string | null;
}

export interface UpsertSpFmStatInput {
  sp_preference_level?: FmSpStatPreferenceLevel | null;
}

export interface UserCreateInput {
  firebase_id: string;
  first_name: string;
  last_name: string;
  login_email: string;
  work_email?: string | null;
  phone_number?: string | null;
  organization?: OrganizationCreateInput | null;
  invited_by?: number | null;
}

export interface UserExtraInput {
  last_service_id?: number | null;
}

export interface UserInviteCreate {
  email: string;
}

export interface UserInviteInput {
  invite_id?: string | null;
  email: string;
}

export interface UserNotificationSettingInput {
  event_id: number;
  service_id?: number | null;
  sms_enabled: boolean;
  email_enabled: boolean;
  email_reminder_enabled: boolean;
}

export interface UserUpdateInput {
  first_name?: string | null;
  last_name?: string | null;
  login_email: string;
  work_email?: string | null;
  phone_number?: string | null;
}

export interface WhereBidComponentInput {
  and?: WhereBidComponentInput[] | null;
  or?: WhereBidComponentInput[] | null;
  component_id?: WhereOperationsInput | null;
  bid_id?: WhereOperationsInput | null;
  site_service_id?: WhereOperationsInput | null;
  variation_id?: WhereOperationsInput | null;
  include_in_award?: WhereOperationsInput | null;
  price?: WhereOperationsInput | null;
  accepted?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  note?: WhereOperationsInput | null;
  fm_note?: WhereOperationsInput | null;
  fm_note_resolved_on?: WhereOperationsInput | null;
  answers?: WhereOperationsInput | null;
  bid?: WhereBidInput | null;
  siteService?: WhereSiteServiceInput | null;
  variation?: WhereSiteServiceVariationInput | null;
}

export interface WhereBidDeclineReasonInput {
  and?: WhereBidDeclineReasonInput[] | null;
  or?: WhereBidDeclineReasonInput[] | null;
  reason_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereBidDocumentRequestInput {
  and?: WhereBidDocumentRequestInput[] | null;
  or?: WhereBidDocumentRequestInput[] | null;
  request_id?: WhereOperationsInput | null;
  bid_id?: WhereOperationsInput | null;
  file_category_id?: WhereOperationsInput | null;
  note?: WhereOperationsInput | null;
  requested_by?: WhereOperationsInput | null;
  requested_on?: WhereOperationsInput | null;
  completed_by?: WhereOperationsInput | null;
  completed_on?: WhereOperationsInput | null;
  reviewed_by?: WhereOperationsInput | null;
  reviewed_on?: WhereOperationsInput | null;
  review_result?: WhereOperationsInput | null;
  bid?: WhereBidInput | null;
  fileCategory?: WhereFileCategoryInput | null;
  requestedBy?: WhereUserInput | null;
  completedBy?: WhereUserInput | null;
  reviewedBy?: WhereUserInput | null;
}

export interface WhereBidEnvelopeFileInput {
  and?: WhereBidEnvelopeFileInput[] | null;
  or?: WhereBidEnvelopeFileInput[] | null;
  env_file_id?: WhereOperationsInput | null;
  envelope_id?: WhereOperationsInput | null;
  file_id?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  initialed?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  error_code?: WhereOperationsInput | null;
  envelope?: WhereBidEnvelopeInput | null;
  file?: WhereOrganizationFileInput | null;
}

export interface WhereBidEnvelopeInput {
  and?: WhereBidEnvelopeInput[] | null;
  or?: WhereBidEnvelopeInput[] | null;
  envelope_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  sp_organization_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  docusign_id?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  sent_on?: WhereOperationsInput | null;
  sent_by?: WhereOperationsInput | null;
  sp_signed_on?: WhereOperationsInput | null;
  fm_signed_on?: WhereOperationsInput | null;
  failed_on?: WhereOperationsInput | null;
  sp_name?: WhereOperationsInput | null;
  fm?: WhereOrganizationInput | null;
  sp?: WhereOrganizationInput | null;
  service?: WhereServiceInput | null;
  status?: WhereCalcBidEnvelope_StatusInput | null;
  bids?: WhereBidInput | null;
  roleStatus?: WhereCalcBidEnvelope_RoleStatusInput | null;
  filesCount?: WhereOperationsInput | null;
  bidsCount?: WhereOperationsInput | null;
  sitesCount?: WhereOperationsInput | null;
  completedOn?: WhereOperationsInput | null;
  roleRecipients?: WhereCalcBidEnvelope_RoleRecipientsInput | null;
  fmRecipients?: WhereCalcBidEnvelope_SpRecipientsInput | null;
  spRecipients?: WhereCalcBidEnvelope_SpRecipientsInput | null;
  files?: WhereBidEnvelopeFileInput | null;
  recipients?: WhereBidEnvelopeRecipientInput | null;
  sentBy?: WhereUserInput | null;
}

export interface WhereBidEnvelopeRecipientInput {
  and?: WhereBidEnvelopeRecipientInput[] | null;
  or?: WhereBidEnvelopeRecipientInput[] | null;
  recipient_id?: WhereOperationsInput | null;
  envelope_id?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  role?: WhereOperationsInput | null;
  docusign_role?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  email?: WhereOperationsInput | null;
  client_user_id?: WhereOperationsInput | null;
  du_order?: WhereOperationsInput | null;
  fulfilled_on?: WhereOperationsInput | null;
  secret_token?: WhereOperationsInput | null;
  envelope?: WhereBidEnvelopeInput | null;
}

export interface WhereBidInput {
  and?: WhereBidInput[] | null;
  or?: WhereBidInput[] | null;
  bid_id?: WhereOperationsInput | null;
  bundle_id?: WhereOperationsInput | null;
  envelope_id?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  decline_reason_id?: WhereOperationsInput | null;
  decline_note?: WhereOperationsInput | null;
  reject_reason_id?: WhereOperationsInput | null;
  reject_note?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  started_negotiation_on?: WhereOperationsInput | null;
  award_pending_on?: WhereOperationsInput | null;
  accepted_on?: WhereOperationsInput | null;
  declined_on?: WhereOperationsInput | null;
  submitted_on?: WhereOperationsInput | null;
  started_on?: WhereOperationsInput | null;
  created_by?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  accepted_by?: WhereOperationsInput | null;
  submitted_by?: WhereOperationsInput | null;
  declined_by?: WhereOperationsInput | null;
  invited_on?: WhereOperationsInput | null;
  start_date?: WhereOperationsInput | null;
  end_date?: WhereOperationsInput | null;
  last_notified_on?: WhereOperationsInput | null;
  app_message_sent?: WhereOperationsInput | null;
  notified_times?: WhereOperationsInput | null;
  invited_by?: WhereOperationsInput | null;
  open_count?: WhereOperationsInput | null;
  last_opened_on?: WhereOperationsInput | null;
  sent_email_times?: WhereOperationsInput | null;
  submit_comment?: WhereOperationsInput | null;
  agreement_initiated_by?: WhereOperationsInput | null;
  agreement_docusign_id?: WhereOperationsInput | null;
  agreement_docusign_secret?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  awarded_partially?: WhereOperationsInput | null;
  active?: WhereOperationsInput | null;
  spUnresolvedNotesCount?: WhereOperationsInput | null;
  unresolvedNotesCount?: WhereOperationsInput | null;
  fmUnresolvedNotesCount?: WhereOperationsInput | null;
  filesCount?: WhereOperationsInput | null;
  bundleStatus?: WhereCalcBid_BundleStatusInput | null;
  spStatus?: WhereCalcBid_SpStatusInput | null;
  spBidAllowance?: WhereOperationsInput | null;
  spBidAllowanceBypassCc?: WhereOperationsInput | null;
  spDocumentsSummary?: WhereCalcBid_SpDocumentsSummaryInput | null;
  documentsSummary?: WhereCalcBid_FmDocumentsSummaryInput | null;
  fmDocumentsSummary?: WhereCalcBid_FmDocumentsSummaryInput | null;
  hasBouncedInvite?: WhereOperationsInput | null;
  isGhost?: WhereOperationsInput | null;
  spLastUpdatedOn?: WhereOperationsInput | null;
  lastUpdatedOn?: WhereOperationsInput | null;
  lastBundleUpdatedOn?: WhereOperationsInput | null;
  awardedOn?: WhereOperationsInput | null;
  spLastBundleUpdatedOn?: WhereOperationsInput | null;
  fmLastUpdatedOn?: WhereOperationsInput | null;
  fmLastBundleUpdatedOn?: WhereOperationsInput | null;
  endDateRolling?: WhereOperationsInput | null;
  rank?: WhereBidRankInput | null;
  bundle?: WhereBundleInput | null;
  envelope?: WhereBidEnvelopeInput | null;
  _status?: WhereBidStatusInput | null;
  declineReason?: WhereBidDeclineReasonInput | null;
  rejectReason?: WhereBidRejectReasonInput | null;
  createdBy?: WhereUserInput | null;
  organization?: WhereOrganizationInput | null;
  acceptedBy?: WhereUserInput | null;
  declinedBy?: WhereUserInput | null;
  submittedBy?: WhereUserInput | null;
  invitedBy?: WhereUserInput | null;
  agreementInitiatedBy?: WhereUserInput | null;
  components?: WhereBidComponentInput | null;
  documentRequests?: WhereBidDocumentRequestInput | null;
}

export interface WhereBidRankInput {
  and?: WhereBidRankInput[] | null;
  or?: WhereBidRankInput[] | null;
  bid_id?: WhereOperationsInput | null;
  active?: WhereOperationsInput | null;
  bid?: WhereBidInput | null;
}

export interface WhereBidRejectReasonInput {
  and?: WhereBidRejectReasonInput[] | null;
  or?: WhereBidRejectReasonInput[] | null;
  reason_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereBidStatusInput {
  and?: WhereBidStatusInput[] | null;
  or?: WhereBidStatusInput[] | null;
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereBundleInput {
  and?: WhereBundleInput[] | null;
  or?: WhereBundleInput[] | null;
  bundle_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  created_by?: WhereOperationsInput | null;
  public_note?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  screener_id?: WhereOperationsInput | null;
  contracts?: WhereCalcBundle_ContractsInput | null;
  contractTypes?: WhereCalcBundle_ContractTypesInput | null;
  states?: WhereCalcBundle_StatesInput | null;
  units?: WhereCalcBundle_UnitsInput | null;
  ghostsCount?: WhereOperationsInput | null;
  awardedOrganization?: WhereCalcBundle_AwardedOrganizationInput | null;
  active?: WhereOperationsInput | null;
  hasBidsIn?: WhereOperationsInput | null;
  fmEnvelopeStatus?: WhereCalcBundle_FmEnvelopeStatusInput | null;
  spLastUpdatedOn?: WhereOperationsInput | null;
  lastUpdatedOn?: WhereOperationsInput | null;
  lastBundleUpdatedOn?: WhereOperationsInput | null;
  awardedOn?: WhereOperationsInput | null;
  fmLastUpdatedOn?: WhereOperationsInput | null;
  adminLastUpdatedOn?: WhereOperationsInput | null;
  spServicedSites?: WhereCalcBundle_SpServicedSitesInput | null;
  serviced?: WhereCalcBundle_ServicedInput | null;
  spInvite?: WhereCalcBundle_SpInviteInput | null;
  fmSpStat?: WhereCalcBundle_FmSpStatInput | null;
  rank?: WhereBundleRankInput | null;
  summary?: WhereBundleSummaryInput | null;
  sites?: WhereBundleSiteInput | null;
  bids?: WhereBidInput | null;
  createdBy?: WhereUserInput | null;
  organization?: WhereOrganizationInput | null;
  service?: WhereServiceInput | null;
  status?: WhereBundleStatusInput | null;
  screener?: WhereOrganizationScreenerInput | null;
}

export interface WhereBundleRankInput {
  and?: WhereBundleRankInput[] | null;
  or?: WhereBundleRankInput[] | null;
  bundle_id?: WhereOperationsInput | null;
  active?: WhereOperationsInput | null;
  bundle?: WhereBundleInput | null;
}

export interface WhereBundleSiteInput {
  and?: WhereBundleSiteInput[] | null;
  or?: WhereBundleSiteInput[] | null;
  bundle_site_id?: WhereOperationsInput | null;
  bundle_id?: WhereOperationsInput | null;
  site_id?: WhereOperationsInput | null;
  added_on?: WhereOperationsInput | null;
  added_by?: WhereOperationsInput | null;
  deleted_on?: WhereOperationsInput | null;
  deleted_by?: WhereOperationsInput | null;
  bundle?: WhereBundleInput | null;
  site?: WhereSiteInput | null;
  addedBy?: WhereUserInput | null;
  deletedBy?: WhereUserInput | null;
}

export interface WhereBundleStatusInput {
  and?: WhereBundleStatusInput[] | null;
  or?: WhereBundleStatusInput[] | null;
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereBundleSummaryInput {
  and?: WhereBundleSummaryInput[] | null;
  or?: WhereBundleSummaryInput[] | null;
  bundle_id?: WhereOperationsInput | null;
  invited?: WhereOperationsInput | null;
  started?: WhereOperationsInput | null;
  submitted?: WhereOperationsInput | null;
  negotiation?: WhereOperationsInput | null;
  award_pending?: WhereOperationsInput | null;
  rejected?: WhereOperationsInput | null;
  declined?: WhereOperationsInput | null;
  last_invited?: WhereOperationsInput | null;
  last_submitted?: WhereOperationsInput | null;
  last_declined?: WhereOperationsInput | null;
  start_date?: WhereOperationsInput | null;
  end_date?: WhereOperationsInput | null;
  sites_states?: WhereOperationsInput | null;
  sites_cities?: WhereOperationsInput | null;
  sites_labels?: WhereOperationsInput | null;
  docs_requested?: WhereOperationsInput | null;
  docs_pending?: WhereOperationsInput | null;
  docs_completed?: WhereOperationsInput | null;
  docs_rejected?: WhereOperationsInput | null;
  sites?: WhereOperationsInput | null;
  bundle?: WhereBundleInput | null;
}

export interface WhereCalcBidEnvelope_RoleRecipientsInput {
  type?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  email?: WhereOperationsInput | null;
}

export interface WhereCalcBidEnvelope_RoleStatusInput {
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcBidEnvelope_SpRecipientsInput {
  type?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  email?: WhereOperationsInput | null;
}

export interface WhereCalcBidEnvelope_StatusInput {
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcBid_BundleStatusInput {
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcBid_FmDocumentsSummaryInput {
  pending?: WhereOperationsInput | null;
  requested?: WhereOperationsInput | null;
  completed?: WhereOperationsInput | null;
  rejected?: WhereOperationsInput | null;
}

export interface WhereCalcBid_SpDocumentsSummaryInput {
  pending?: WhereOperationsInput | null;
  requested?: WhereOperationsInput | null;
  completed?: WhereOperationsInput | null;
  rejected?: WhereOperationsInput | null;
}

export interface WhereCalcBid_SpStatusInput {
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_AwardedOrganizationInput {
  organization_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_ContractTypesInput {
  contract_type_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_ContractsInput {
  contract_term_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_FmEnvelopeStatusInput {
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_FmSpStatInput {
  levels?: WhereOperationsInput | null;
  blacklisted?: WhereOperationsInput | null;
  sp_blacklisted?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_ServicedInput {
  total?: WhereOperationsInput | null;
  serviced?: WhereOperationsInput | null;
  percentage?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_SpInviteInput {
  invited_on?: WhereOperationsInput | null;
  bid_id?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_SpServicedSitesInput {
  total?: WhereOperationsInput | null;
  serviced?: WhereOperationsInput | null;
  percentage?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_StatesInput {
  state_code?: WhereOperationsInput | null;
}

export interface WhereCalcBundle_UnitsInput {
  unit_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
}

export interface WhereCalcFmSpStat_AwardedBundlesInput {
  bundle_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  status_name?: WhereOperationsInput | null;
  status_rank?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
}

export interface WhereCalcFmSpStat_SpStatLevelInput {
  levels?: WhereOperationsInput | null;
  blacklisted?: WhereOperationsInput | null;
  sp_blacklisted?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcImport_SummaryInput {
  queuedCount?: WhereOperationsInput | null;
  inProgressCount?: WhereOperationsInput | null;
  successCount?: WhereOperationsInput | null;
  insertedCount?: WhereOperationsInput | null;
  updatedCount?: WhereOperationsInput | null;
  errorCount?: WhereOperationsInput | null;
  total?: WhereOperationsInput | null;
}

export interface WhereCalcOrganization_FmSpStatInput {
  levels?: WhereOperationsInput | null;
  blacklisted?: WhereOperationsInput | null;
  sp_blacklisted?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcOrganization_SpPreferenceInput {
  levels?: WhereOperationsInput | null;
  blacklisted?: WhereOperationsInput | null;
  sp_blacklisted?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereCalcSite_AdminAwardedToInput {
  organization_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
}

export interface WhereCalcSite_AdminBundlesInput {
  bundle_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  status_name?: WhereOperationsInput | null;
  status_rank?: WhereOperationsInput | null;
  updatedOn?: WhereOperationsInput | null;
  end_date?: WhereOperationsInput | null;
  end_date_sort?: WhereOperationsInput | null;
}

export interface WhereCalcSite_BidsInput {
  bid_id?: WhereOperationsInput | null;
  submitted_on?: WhereOperationsInput | null;
  bundle_id?: WhereOperationsInput | null;
  bundle_name?: WhereOperationsInput | null;
  bundle_organization_id?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  status_name?: WhereOperationsInput | null;
  status_color?: WhereOperationsInput | null;
  status_icon?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  organization_name?: WhereOperationsInput | null;
  procursys_index_id?: WhereOperationsInput | null;
}

export interface WhereCalcSite_BundlesSummaryInput {
  started?: WhereOperationsInput | null;
  submitted?: WhereOperationsInput | null;
  declined?: WhereOperationsInput | null;
  rejected?: WhereOperationsInput | null;
}

export interface WhereCalcSite_FmAwardedToInput {
  organization_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
}

export interface WhereCalcSite_FmBundlesInput {
  bundle_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  status_name?: WhereOperationsInput | null;
  status_rank?: WhereOperationsInput | null;
  updatedOn?: WhereOperationsInput | null;
  end_date?: WhereOperationsInput | null;
  end_date_sort?: WhereOperationsInput | null;
}

export interface WhereCalcSite_SpBundlesInput {
  bundle_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  status_name?: WhereOperationsInput | null;
  status_rank?: WhereOperationsInput | null;
  updatedOn?: WhereOperationsInput | null;
  end_date?: WhereOperationsInput | null;
  end_date_sort?: WhereOperationsInput | null;
}

export interface WhereCalcUserApproval_InviteInput {
  invite_id?: WhereOperationsInput | null;
  invited_by_user_id?: WhereOperationsInput | null;
  invited_by_user_name?: WhereOperationsInput | null;
  invited_by_is_system_admin?: WhereOperationsInput | null;
  invited_by_organization_id?: WhereOperationsInput | null;
  invited_by_organization_name?: WhereOperationsInput | null;
}

export interface WhereContractTermInput {
  and?: WhereContractTermInput[] | null;
  or?: WhereContractTermInput[] | null;
  contract_term_id?: WhereOperationsInput | null;
  owner_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  start_date?: WhereOperationsInput | null;
  end_date?: WhereOperationsInput | null;
  owner?: WherePropertyOwnerInput | null;
  organization?: WhereOrganizationInput | null;
  service?: WhereServiceInput | null;
  sites?: WhereSiteInput | null;
  files?: WhereOrganizationFileInput | null;
  hasBundledSites?: WhereOperationsInput | null;
  sitesCount?: WhereOperationsInput | null;
  filesCount?: WhereOperationsInput | null;
}

export interface WhereContractTypeInput {
  and?: WhereContractTypeInput[] | null;
  or?: WhereContractTypeInput[] | null;
  contract_type_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  description?: WhereOperationsInput | null;
}

export interface WhereCountryInput {
  and?: WhereCountryInput[] | null;
  or?: WhereCountryInput[] | null;
  country_code?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereDashboardWidgetInput {
  and?: WhereDashboardWidgetInput[] | null;
  or?: WhereDashboardWidgetInput[] | null;
  widget_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  description?: WhereOperationsInput | null;
  required_permissions?: WhereOperationsInput | null;
  permitted?: WhereOperationsInput | null;
}

export interface WhereEulaInput {
  and?: WhereEulaInput[] | null;
  or?: WhereEulaInput[] | null;
  eula_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  eula_date?: WhereOperationsInput | null;
  userEulas?: WhereUserEulaInput | null;
}

export interface WhereFileCategoryInput {
  and?: WhereFileCategoryInput[] | null;
  or?: WhereFileCategoryInput[] | null;
  category_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereFmCustomFieldInput {
  and?: WhereFmCustomFieldInput[] | null;
  or?: WhereFmCustomFieldInput[] | null;
  field_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  context?: WhereOperationsInput | null;
  placeholder?: WhereOperationsInput | null;
  fm_organization_id?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  values?: WhereFmSpFieldValueInput | null;
}

export interface WhereFmSpFieldValueInput {
  and?: WhereFmSpFieldValueInput[] | null;
  or?: WhereFmSpFieldValueInput[] | null;
  field_value_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  field_id?: WhereOperationsInput | null;
  value?: WhereOperationsInput | null;
  set_by?: WhereOperationsInput | null;
  set_on?: WhereOperationsInput | null;
  fm_organization_id?: WhereOperationsInput | null;
  setBy?: WhereUserInput | null;
  field?: WhereFmCustomFieldInput | null;
  fmOrganization?: WhereOrganizationInput | null;
  organization?: WhereOrganizationInput | null;
}

export interface WhereFmSpStatInput {
  and?: WhereFmSpStatInput[] | null;
  or?: WhereFmSpStatInput[] | null;
  fm_organization_id?: WhereOperationsInput | null;
  sp_organization_id?: WhereOperationsInput | null;
  is_incumbent?: WhereOperationsInput | null;
  has_worked_with?: WhereOperationsInput | null;
  preference_level?: WhereOperationsInput | null;
  sp_preference_level?: WhereOperationsInput | null;
  sp_blacklisted_on?: WhereOperationsInput | null;
  sp_blacklisted_by?: WhereOperationsInput | null;
  fmOrg?: WhereOrganizationInput | null;
  spOrg?: WhereOrganizationInput | null;
  spBlacklistedBy?: WhereUserInput | null;
  awardedBundles?: WhereCalcFmSpStat_AwardedBundlesInput | null;
  awardedBundlesCount?: WhereOperationsInput | null;
  spStatLevel?: WhereCalcFmSpStat_SpStatLevelInput | null;
  serviceFieldValue?: WhereOperationsInput | null;
  annual_revenue?: WhereOperationsInput | null;
  years_servicing?: WhereOperationsInput | null;
  fulltime_employees?: WhereOperationsInput | null;
  parttime_employees?: WhereOperationsInput | null;
  business_entity_type?: WhereOperationsInput | null;
  business_classifications?: WhereOperationsInput | null;
  business_owned_by?: WhereOperationsInput | null;
}

export interface WhereImportInput {
  and?: WhereImportInput[] | null;
  or?: WhereImportInput[] | null;
  import_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  completed_hook_executed?: WhereOperationsInput | null;
  from_row?: WhereOperationsInput | null;
  to_row?: WhereOperationsInput | null;
  filename?: WhereOperationsInput | null;
  path?: WhereOperationsInput | null;
  imported_by?: WhereOperationsInput | null;
  imported_on?: WhereOperationsInput | null;
  metadata?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  importedBy?: WhereUserInput | null;
  summary?: WhereCalcImport_SummaryInput | null;
}

export interface WhereImportRecordInput {
  and?: WhereImportRecordInput[] | null;
  or?: WhereImportRecordInput[] | null;
  record_id?: WhereOperationsInput | null;
  message_id?: WhereOperationsInput | null;
  import_id?: WhereOperationsInput | null;
  status?: WhereOperationsInput | null;
  reference_id?: WhereOperationsInput | null;
  reference_action?: WhereOperationsInput | null;
  address_score?: WhereOperationsInput | null;
  address?: WhereOperationsInput | null;
  index?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  context?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  metadata?: WhereOperationsInput | null;
  import?: WhereImportInput | null;
}

export interface WhereIntegrationInput {
  and?: WhereIntegrationInput[] | null;
  or?: WhereIntegrationInput[] | null;
  integration_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  logo_url?: WhereOperationsInput | null;
}

export interface WhereLocationInput {
  and?: WhereLocationInput[] | null;
  or?: WhereLocationInput[] | null;
  location_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  address_line_1?: WhereOperationsInput | null;
  address_line_2?: WhereOperationsInput | null;
  city?: WhereOperationsInput | null;
  county?: WhereOperationsInput | null;
  state_code?: WhereOperationsInput | null;
  postal_code?: WhereOperationsInput | null;
  region?: WhereOperationsInput | null;
  latlong?: WhereOperationsInput | null;
  address_score?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  sites?: WhereSiteInput | null;
  postalCode?: WherePostalCodeInput | null;
  state?: WhereStateInput | null;
}

export interface WhereNotificationEventInput {
  and?: WhereNotificationEventInput[] | null;
  or?: WhereNotificationEventInput[] | null;
  event_id?: WhereOperationsInput | null;
  key?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  per_service?: WhereOperationsInput | null;
  has_email?: WhereOperationsInput | null;
  has_email_reminder?: WhereOperationsInput | null;
  has_sms?: WhereOperationsInput | null;
}

export interface WhereOperationsInput {
  eq?: any | null;
  not?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  in?: any[] | null;
  notIn?: any[] | null;
  between?: any[] | null;
  notBetween?: number[] | null;
  like?: string | null;
  iLike?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  isNull?: boolean | null;
  jsArrayIncludes?: string | null;
  jsArrayNotIncludes?: string | null;
  jsArraySome?: string[] | null;
  jsArrayAll?: string[] | null;
  jsArrayNotSome?: string[] | null;
  jsArrayNotAll?: string[] | null;
  jsArrayMatch?: string | null;
  jsArrayNotMatch?: string | null;
  jsArrayStartsWith?: string | null;
  jsArrayEndsWith?: string | null;
}

export interface WhereOrganizationAddressInput {
  and?: WhereOrganizationAddressInput[] | null;
  or?: WhereOrganizationAddressInput[] | null;
  address_id?: WhereOperationsInput | null;
  address_line_1?: WhereOperationsInput | null;
  address_line_2?: WhereOperationsInput | null;
  city?: WhereOperationsInput | null;
  state_code?: WhereOperationsInput | null;
  country_code?: WhereOperationsInput | null;
  postal_code?: WhereOperationsInput | null;
  operation_hours?: WhereOperationsInput | null;
  google_place_id?: WhereOperationsInput | null;
  latlong?: WhereOperationsInput | null;
}

export interface WhereOrganizationFileInput {
  and?: WhereOrganizationFileInput[] | null;
  or?: WhereOrganizationFileInput[] | null;
  file_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  path?: WhereOperationsInput | null;
  mime_type?: WhereOperationsInput | null;
  created_by?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  category_id?: WhereOperationsInput | null;
  for_organization_id?: WhereOperationsInput | null;
  property_owner_id?: WhereOperationsInput | null;
  contract_term_id?: WhereOperationsInput | null;
  site_id?: WhereOperationsInput | null;
  bid_id?: WhereOperationsInput | null;
  from_file_id?: WhereOperationsInput | null;
  show_in_repository?: WhereOperationsInput | null;
  is_public?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  createdBy?: WhereUserInput | null;
  fromFile?: WhereOrganizationFileInput | null;
  contractTerm?: WhereContractTermInput | null;
  bid?: WhereBidInput | null;
  property_owner?: WherePropertyOwnerInput | null;
  for_organization?: WhereOrganizationInput | null;
  site?: WhereSiteInput | null;
  service?: WhereServiceInput | null;
  category?: WhereFileCategoryInput | null;
}

export interface WhereOrganizationInput {
  and?: WhereOrganizationInput[] | null;
  or?: WhereOrganizationInput[] | null;
  organization_id?: WhereOperationsInput | null;
  uuid?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  sp_profile_id?: WhereOperationsInput | null;
  sp_procursys_index?: WhereOperationsInput | null;
  ein?: WhereOperationsInput | null;
  dba?: WhereOperationsInput | null;
  is_service_provider?: WhereOperationsInput | null;
  is_facility_manager?: WhereOperationsInput | null;
  website_url?: WhereOperationsInput | null;
  phone_number?: WhereOperationsInput | null;
  about?: WhereOperationsInput | null;
  help?: WhereOperationsInput | null;
  stripe_customer_id?: WhereOperationsInput | null;
  stripe_subscription_id?: WhereOperationsInput | null;
  stripe_unsubscribed?: WhereOperationsInput | null;
  stripe_cancel_reason?: WhereOperationsInput | null;
  stripe_on_auto_renew?: WhereOperationsInput | null;
  hubspot_company_id?: WhereOperationsInput | null;
  qbo_id?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  created_by?: WhereOperationsInput | null;
  address_id?: WhereOperationsInput | null;
  twilio_number?: WhereOperationsInput | null;
  logo_url?: WhereOperationsInput | null;
  fm_system_color?: WhereOperationsInput | null;
  is_approved?: WhereOperationsInput | null;
  is_deactivated?: WhereOperationsInput | null;
  deactivated_on?: WhereOperationsInput | null;
  fm_docusign_access?: WhereOperationsInput | null;
  sp_bid_access?: WhereOperationsInput | null;
  sp_bid_import_access?: WhereOperationsInput | null;
  fm_sp_bid_accept_required?: WhereOperationsInput | null;
  address?: WhereOrganizationAddressInput | null;
  spProfile?: WhereSpOrganizationProfileInput | null;
  procursysIndex?: WhereProcursysIndexInput | null;
  serviceFieldValue?: WhereOperationsInput | null;
  annual_revenue?: WhereOperationsInput | null;
  years_servicing?: WhereOperationsInput | null;
  fulltime_employees?: WhereOperationsInput | null;
  parttime_employees?: WhereOperationsInput | null;
  business_entity_type?: WhereOperationsInput | null;
  business_classifications?: WhereOperationsInput | null;
  business_owned_by?: WhereOperationsInput | null;
  sitesManagedCount?: WhereOperationsInput | null;
  sites_serviced_count?: WhereOperationsInput | null;
  bundleSitesInOrgState?: WhereOperationsInput | null;
  invitedToBundleOn?: WhereOperationsInput | null;
  usersCount?: WhereOperationsInput | null;
  actualUsersCount?: WhereOperationsInput | null;
  activeInvitesCount?: WhereOperationsInput | null;
  fmSpStat?: WhereCalcOrganization_FmSpStatInput | null;
  spPreference?: WhereCalcOrganization_SpPreferenceInput | null;
  filesCount?: WhereOperationsInput | null;
  hasFileOfCategory?: WhereOperationsInput | null;
  organizationType?: WhereOperationsInput | null;
  status?: WhereOperationsInput | null;
  subscriptionStatus?: WhereOperationsInput | null;
  approvedOn?: WhereOperationsInput | null;
  accountStatus?: WhereOperationsInput | null;
  bundles?: WhereBundleInput | null;
  users?: WhereUserInput | null;
  createdBy?: WhereUserInput | null;
  services?: WhereServiceInput | null;
  serviceFieldValues?: WhereOrganizationServiceFieldValueInput | null;
  files?: WhereOrganizationFileInput | null;
  userInvites?: WhereUserInviteInput | null;
}

export interface WhereOrganizationRegionAreaInput {
  and?: WhereOrganizationRegionAreaInput[] | null;
  or?: WhereOrganizationRegionAreaInput[] | null;
  area_id?: WhereOperationsInput | null;
  region_id?: WhereOperationsInput | null;
  state_code?: WhereOperationsInput | null;
  region?: WhereOrganizationRegionInput | null;
  state?: WhereStateInput | null;
}

export interface WhereOrganizationRegionInput {
  and?: WhereOrganizationRegionInput[] | null;
  or?: WhereOrganizationRegionInput[] | null;
  region_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  created_by?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  createdBy?: WhereUserInput | null;
  organization?: WhereOrganizationInput | null;
  areas?: WhereOrganizationRegionAreaInput | null;
}

export interface WhereOrganizationScreenerFieldInput {
  and?: WhereOrganizationScreenerFieldInput[] | null;
  or?: WhereOrganizationScreenerFieldInput[] | null;
  screener_field_id?: WhereOperationsInput | null;
  screener_id?: WhereOperationsInput | null;
  configuration?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  screener?: WhereOrganizationScreenerInput | null;
}

export interface WhereOrganizationScreenerInput {
  and?: WhereOrganizationScreenerInput[] | null;
  or?: WhereOrganizationScreenerInput[] | null;
  screener_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  is_enabled?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  service?: WhereServiceInput | null;
  fields?: WhereOrganizationScreenerFieldInput | null;
  answersCount?: WhereOperationsInput | null;
  bundlesCount?: WhereOperationsInput | null;
  activeBundlesCount?: WhereOperationsInput | null;
  fieldsCount?: WhereOperationsInput | null;
}

export interface WhereOrganizationServiceAreaInput {
  and?: WhereOrganizationServiceAreaInput[] | null;
  or?: WhereOrganizationServiceAreaInput[] | null;
  area_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  postal_code?: WhereOperationsInput | null;
  state_code?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  service?: WhereServiceInput | null;
  postalCode?: WherePostalCodeInput | null;
  state?: WhereStateInput | null;
}

export interface WhereOrganizationServiceFieldValueInput {
  and?: WhereOrganizationServiceFieldValueInput[] | null;
  or?: WhereOrganizationServiceFieldValueInput[] | null;
  field_value_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  field_id?: WhereOperationsInput | null;
  value?: WhereOperationsInput | null;
  set_by?: WhereOperationsInput | null;
  set_on?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  setBy?: WhereUserInput | null;
  organization?: WhereOrganizationInput | null;
  service?: WhereServiceInput | null;
  field?: WhereServiceFieldInput | null;
}

export interface WherePermissionInput {
  and?: WherePermissionInput[] | null;
  or?: WherePermissionInput[] | null;
  permission_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  description?: WhereOperationsInput | null;
  applies_to_service_providers?: WhereOperationsInput | null;
  applies_to_facility_managers?: WhereOperationsInput | null;
  is_default_for_service_providers?: WhereOperationsInput | null;
  is_default_for_facility_managers?: WhereOperationsInput | null;
  is_regional?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  userPermissions?: WhereUserPermissionInput | null;
}

export interface WherePostalCodeInput {
  and?: WherePostalCodeInput[] | null;
  or?: WherePostalCodeInput[] | null;
  postal_code?: WhereOperationsInput | null;
  state_code?: WhereOperationsInput | null;
  country_code?: WhereOperationsInput | null;
  latlong?: WhereOperationsInput | null;
  covered_by?: WhereOperationsInput | null;
  state?: WhereStateInput | null;
  country?: WhereCountryInput | null;
  shape?: WherePostalCodeShapeInput | null;
  coveredBy?: WhereCountryInput | null;
}

export interface WherePostalCodeShapeInput {
  and?: WherePostalCodeShapeInput[] | null;
  or?: WherePostalCodeShapeInput[] | null;
  postal_code?: WhereOperationsInput | null;
  shape?: WhereOperationsInput | null;
  postalCode?: WherePostalCodeInput | null;
  simplifiedShape?: WhereOperationsInput | null;
  postalShape?: WhereOperationsInput | null;
  stateShape?: WhereOperationsInput | null;
}

export interface WhereProcursysIndexInput {
  and?: WhereProcursysIndexInput[] | null;
  or?: WhereProcursysIndexInput[] | null;
  index_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  description?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WherePropertyOwnerInput {
  and?: WherePropertyOwnerInput[] | null;
  or?: WherePropertyOwnerInput[] | null;
  owner_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  sites?: WhereSiteInput | null;
}

export interface WhereReportInput {
  and?: WhereReportInput[] | null;
  or?: WhereReportInput[] | null;
  report_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  category_name?: WhereOperationsInput | null;
  description?: WhereOperationsInput | null;
  looker_report_url?: WhereOperationsInput | null;
  configuration?: WhereOperationsInput | null;
  for_fm?: WhereOperationsInput | null;
  for_sp?: WhereOperationsInput | null;
  for_admin?: WhereOperationsInput | null;
}

export interface WhereServiceFieldInput {
  and?: WhereServiceFieldInput[] | null;
  or?: WhereServiceFieldInput[] | null;
  field_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  context?: WhereOperationsInput | null;
  placeholder?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  level?: WhereOperationsInput | null;
  is_required?: WhereOperationsInput | null;
  service?: WhereServiceInput | null;
  values?: WhereOrganizationServiceFieldValueInput | null;
}

export interface WhereServiceInput {
  and?: WhereServiceInput[] | null;
  or?: WhereServiceInput[] | null;
  service_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  slug?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  enabled?: WhereOperationsInput | null;
  organizations?: WhereOrganizationInput | null;
  fields?: WhereServiceFieldInput | null;
  files?: WhereOrganizationFileInput | null;
}

export interface WhereServiceUnitInput {
  and?: WhereServiceUnitInput[] | null;
  or?: WhereServiceUnitInput[] | null;
  unit_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  abbr?: WhereOperationsInput | null;
}

export interface WhereSiteInput {
  and?: WhereSiteInput[] | null;
  or?: WhereSiteInput[] | null;
  site_id?: WhereOperationsInput | null;
  location_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  store_number?: WhereOperationsInput | null;
  external_id?: WhereOperationsInput | null;
  owner_id?: WhereOperationsInput | null;
  service_contract_id?: WhereOperationsInput | null;
  contract_type_id?: WhereOperationsInput | null;
  sow_file_id?: WhereOperationsInput | null;
  note?: WhereOperationsInput | null;
  labels?: WhereOperationsInput | null;
  siteServicesCount?: WhereOperationsInput | null;
  filesCount?: WhereOperationsInput | null;
  bidInvitesCount?: WhereOperationsInput | null;
  unfinishedBidComponentsCount?: WhereOperationsInput | null;
  bids?: WhereCalcSite_BidsInput | null;
  bundlesSummary?: WhereCalcSite_BundlesSummaryInput | null;
  bundles?: WhereCalcSite_AdminBundlesInput | null;
  fmBundles?: WhereCalcSite_FmBundlesInput | null;
  spBundles?: WhereCalcSite_SpBundlesInput | null;
  adminBundles?: WhereCalcSite_AdminBundlesInput | null;
  spInvited?: WhereOperationsInput | null;
  fmAwardedTo?: WhereCalcSite_FmAwardedToInput | null;
  awardedTo?: WhereCalcSite_AdminAwardedToInput | null;
  adminAwardedTo?: WhereCalcSite_AdminAwardedToInput | null;
  active?: WhereOperationsInput | null;
  owner?: WherePropertyOwnerInput | null;
  status?: WhereSiteStatusInput | null;
  rank?: WhereSiteRankInput | null;
  summary?: WhereSiteSummaryInput | null;
  location?: WhereLocationInput | null;
  service?: WhereServiceInput | null;
  files?: WhereOrganizationFileInput | null;
  sowFile?: WhereOrganizationFileInput | null;
  contractType?: WhereContractTypeInput | null;
  serviceContract?: WhereContractTermInput | null;
  metadata?: WhereSiteMetadataInput | null;
  site_services?: WhereSiteServiceInput | null;
  bundleSites?: WhereBundleSiteInput | null;
}

export interface WhereSiteLabelInput {
  and?: WhereSiteLabelInput[] | null;
  or?: WhereSiteLabelInput[] | null;
  label_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  service?: WhereServiceInput | null;
}

export interface WhereSiteMetadataInput {
  and?: WhereSiteMetadataInput[] | null;
  or?: WhereSiteMetadataInput[] | null;
  datapoint_id?: WhereOperationsInput | null;
  site_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  value?: WhereOperationsInput | null;
  applies_to_services?: WhereOperationsInput | null;
  visible_to?: WhereOperationsInput | null;
  group_name?: WhereOperationsInput | null;
  integration_id?: WhereOperationsInput | null;
  site?: WhereSiteInput | null;
  integration?: WhereIntegrationInput | null;
}

export interface WhereSiteRankInput {
  and?: WhereSiteRankInput[] | null;
  or?: WhereSiteRankInput[] | null;
  site_id?: WhereOperationsInput | null;
  active?: WhereOperationsInput | null;
  site?: WhereSiteInput | null;
}

export interface WhereSiteServiceInput {
  and?: WhereSiteServiceInput[] | null;
  or?: WhereSiteServiceInput[] | null;
  site_service_id?: WhereOperationsInput | null;
  site_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  price_type?: WhereOperationsInput | null;
  service_price_enabled?: WhereOperationsInput | null;
  price?: WhereOperationsInput | null;
  per_unit_id?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  start_date?: WhereOperationsInput | null;
  end_date?: WhereOperationsInput | null;
  questions?: WhereOperationsInput | null;
  site?: WhereSiteInput | null;
  service?: WhereServiceInput | null;
  unit?: WhereServiceUnitInput | null;
  variations?: WhereSiteServiceVariationInput | null;
  bundleSites?: WhereBundleSiteInput | null;
}

export interface WhereSiteServiceVariationInput {
  and?: WhereSiteServiceVariationInput[] | null;
  or?: WhereSiteServiceVariationInput[] | null;
  variation_id?: WhereOperationsInput | null;
  site_service_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  price?: WhereOperationsInput | null;
  per_unit_id?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  questions?: WhereOperationsInput | null;
  site_service?: WhereSiteServiceInput | null;
  unit?: WhereServiceUnitInput | null;
}

export interface WhereSiteStatusInput {
  and?: WhereSiteStatusInput[] | null;
  or?: WhereSiteStatusInput[] | null;
  status_id?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  description?: WhereOperationsInput | null;
  icon?: WhereOperationsInput | null;
  color?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
}

export interface WhereSiteSummaryInput {
  and?: WhereSiteSummaryInput[] | null;
  or?: WhereSiteSummaryInput[] | null;
  site_id?: WhereOperationsInput | null;
  bundles?: WhereOperationsInput | null;
  bundle_names?: WhereOperationsInput | null;
  site?: WhereSiteInput | null;
}

export interface WhereSpBidAllowanceInput {
  and?: WhereSpBidAllowanceInput[] | null;
  or?: WhereSpBidAllowanceInput[] | null;
  allowance_id?: WhereOperationsInput | null;
  fm_organization_id?: WhereOperationsInput | null;
  sp_organization_id?: WhereOperationsInput | null;
  bundle_id?: WhereOperationsInput | null;
  bypass_cc?: WhereOperationsInput | null;
  allowed_until?: WhereOperationsInput | null;
  created_by?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  fmOrganization?: WhereOrganizationInput | null;
  spOrganization?: WhereOrganizationInput | null;
  bundle?: WhereBundleInput | null;
  createdBy?: WhereUserInput | null;
}

export interface WhereSpOrganizationProfileFileInput {
  and?: WhereSpOrganizationProfileFileInput[] | null;
  or?: WhereSpOrganizationProfileFileInput[] | null;
  file_id?: WhereOperationsInput | null;
  profile_id?: WhereOperationsInput | null;
  path?: WhereOperationsInput | null;
  mime_type?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  type?: WhereOperationsInput | null;
  rank?: WhereOperationsInput | null;
  profile?: WhereSpOrganizationProfileInput | null;
}

export interface WhereSpOrganizationProfileInput {
  and?: WhereSpOrganizationProfileInput[] | null;
  or?: WhereSpOrganizationProfileInput[] | null;
  profile_id?: WhereOperationsInput | null;
  about?: WhereOperationsInput | null;
  banner_title?: WhereOperationsInput | null;
  banner_subtitle?: WhereOperationsInput | null;
  years_in_business?: WhereOperationsInput | null;
  phone_number?: WhereOperationsInput | null;
  facebook?: WhereOperationsInput | null;
  instagram?: WhereOperationsInput | null;
  twitter?: WhereOperationsInput | null;
  linkedin?: WhereOperationsInput | null;
  files?: WhereSpOrganizationProfileFileInput | null;
}

export interface WhereStateInput {
  and?: WhereStateInput[] | null;
  or?: WhereStateInput[] | null;
  state_code?: WhereOperationsInput | null;
  country_code?: WhereOperationsInput | null;
  name?: WhereOperationsInput | null;
  shape?: WhereOperationsInput | null;
  country?: WhereCountryInput | null;
  simplifiedShape?: WhereOperationsInput | null;
  postalShape?: WhereOperationsInput | null;
  stateShape?: WhereOperationsInput | null;
}

export interface WhereUserApprovalInput {
  and?: WhereUserApprovalInput[] | null;
  or?: WhereUserApprovalInput[] | null;
  approval_id?: WhereOperationsInput | null;
  user_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  submitted_on?: WhereOperationsInput | null;
  reviewed_on?: WhereOperationsInput | null;
  reviewed_by?: WhereOperationsInput | null;
  is_approved?: WhereOperationsInput | null;
  other_info?: WhereOperationsInput | null;
  rejection_note?: WhereOperationsInput | null;
  user?: WhereUserInput | null;
  organization?: WhereOrganizationInput | null;
  reviewedBy?: WhereUserInput | null;
  invite?: WhereCalcUserApproval_InviteInput | null;
  activeBidsCount?: WhereOperationsInput | null;
}

export interface WhereUserEmailVerificationInput {
  and?: WhereUserEmailVerificationInput[] | null;
  or?: WhereUserEmailVerificationInput[] | null;
  user_id?: WhereOperationsInput | null;
  is_verified?: WhereOperationsInput | null;
  verification_token?: WhereOperationsInput | null;
  user?: WhereUserInput | null;
}

export interface WhereUserEulaInput {
  and?: WhereUserEulaInput[] | null;
  or?: WhereUserEulaInput[] | null;
  user_eula_id?: WhereOperationsInput | null;
  eula_id?: WhereOperationsInput | null;
  user_id?: WhereOperationsInput | null;
  accepted_on?: WhereOperationsInput | null;
  ip_address?: WhereOperationsInput | null;
  eula?: WhereEulaInput | null;
  user?: WhereUserInput | null;
}

export interface WhereUserExtraInput {
  and?: WhereUserExtraInput[] | null;
  or?: WhereUserExtraInput[] | null;
  user_id?: WhereOperationsInput | null;
  last_service_id?: WhereOperationsInput | null;
  user?: WhereUserInput | null;
  lastService?: WhereServiceInput | null;
}

export interface WhereUserInput {
  and?: WhereUserInput[] | null;
  or?: WhereUserInput[] | null;
  user_id?: WhereOperationsInput | null;
  firebase_id?: WhereOperationsInput | null;
  login_email?: WhereOperationsInput | null;
  work_email?: WhereOperationsInput | null;
  first_name?: WhereOperationsInput | null;
  last_name?: WhereOperationsInput | null;
  phone_number?: WhereOperationsInput | null;
  sms_deliverable?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  is_deactivated?: WhereOperationsInput | null;
  deactivated_on?: WhereOperationsInput | null;
  is_approved?: WhereOperationsInput | null;
  approved_on?: WhereOperationsInput | null;
  created_on?: WhereOperationsInput | null;
  updated_on?: WhereOperationsInput | null;
  is_system_admin?: WhereOperationsInput | null;
  last_login_on?: WhereOperationsInput | null;
  last_action_on?: WhereOperationsInput | null;
  fullName?: WhereOperationsInput | null;
  status?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  approval?: WhereUserApprovalInput | null;
  extra?: WhereUserExtraInput | null;
  invite?: WhereUserInviteInput | null;
  acceptedEulas?: WhereUserEulaInput | null;
  userPermissions?: WhereUserPermissionInput | null;
  notificationSettings?: WhereUserNotificationSettingInput | null;
  emailVerification?: WhereUserEmailVerificationInput | null;
}

export interface WhereUserInviteInput {
  and?: WhereUserInviteInput[] | null;
  or?: WhereUserInviteInput[] | null;
  invite_id?: WhereOperationsInput | null;
  organization_id?: WhereOperationsInput | null;
  invited_by?: WhereOperationsInput | null;
  invited_on?: WhereOperationsInput | null;
  email?: WhereOperationsInput | null;
  created_user_id?: WhereOperationsInput | null;
  accepted_on?: WhereOperationsInput | null;
  failed_on?: WhereOperationsInput | null;
  organization?: WhereOrganizationInput | null;
  invitedBy?: WhereUserInput | null;
  createdUser?: WhereUserInput | null;
  invitedByName?: WhereOperationsInput | null;
}

export interface WhereUserNotificationSettingInput {
  and?: WhereUserNotificationSettingInput[] | null;
  or?: WhereUserNotificationSettingInput[] | null;
  setting_id?: WhereOperationsInput | null;
  user_id?: WhereOperationsInput | null;
  event_id?: WhereOperationsInput | null;
  service_id?: WhereOperationsInput | null;
  sms_enabled?: WhereOperationsInput | null;
  email_enabled?: WhereOperationsInput | null;
  email_reminder_enabled?: WhereOperationsInput | null;
  user?: WhereUserInput | null;
  event?: WhereNotificationEventInput | null;
  service?: WhereServiceInput | null;
}

export interface WhereUserPermissionInput {
  and?: WhereUserPermissionInput[] | null;
  or?: WhereUserPermissionInput[] | null;
  user_permission_id?: WhereOperationsInput | null;
  user_id?: WhereOperationsInput | null;
  permission_id?: WhereOperationsInput | null;
  region_id?: WhereOperationsInput | null;
  granted_by?: WhereOperationsInput | null;
  granted_on?: WhereOperationsInput | null;
  user?: WhereUserInput | null;
  permission?: WherePermissionInput | null;
  grantedBy?: WhereUserInput | null;
  region?: WhereOrganizationRegionInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
