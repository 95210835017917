import React, { useEffect, useMemo, useState } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  SvgIcon,
  useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, StyledEngineProvider, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import AnimateHeight from 'react-animate-height';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { PATHS } from 'paths';
import logo from 'assets/logo-white.png';

import gradientSnow from 'assets/sidebar/snow/gradient.svg';
import pictureSnow from 'assets/sidebar/snow/picture.svg';
import topSnow from 'assets/sidebar/snow/top.svg';
import gradientLandscaping from 'assets/sidebar/landscaping/gradient.svg';
import pictureLandscaping from 'assets/sidebar/landscaping/picture.svg';
import topLandscaping from 'assets/sidebar/landscaping/top.svg';
import gradientSweeping from 'assets/sidebar/sweeping/gradient.svg';
import pictureSweeping from 'assets/sidebar/sweeping/picture.svg';
import topSweeping from 'assets/sidebar/sweeping/top.svg';
import gradientWashing from 'assets/sidebar/washing/gradient.svg';
import pictureWashing from 'assets/sidebar/washing/picture.svg';
import topWashing from 'assets/sidebar/washing/top.svg';

import gradientPestControl from 'assets/sidebar/pest-control/gradient.svg';
import picturePestControl from 'assets/sidebar/pest-control/picture.svg';
import gradientShoppingCart from 'assets/sidebar/shopping-cart-retrieval/gradient.svg';
import pictureShoppingCart from 'assets/sidebar/shopping-cart-retrieval/picture.svg';

import { getSmartDrawerOpen, setSmartDrawerOpen } from 'utils/settings';
import { useProfile } from 'api/user/profile';
import { Permissions } from 'api/permissions/permissions';

import useEasterEggs from './useEasterEggs';
import { drawerTheme } from '../theme';
import { useChatUnreadCount } from '../chat/hooks/useChatUnreadCount';
import { MenuListItem, MenuListItemOrDivider, MenuListItemUrl } from './menu';
import { NavType } from './navType';
import { MenuIconButton } from './MenuIconButton';
import { MenuItem } from './MenuItem';
import { useUserPermissionChecker } from '../user/useUserPermissionChecker';

interface CustomDrawerStylesProps {
  widthOpen: number;
  widthClose: number;
}

const useStyles = makeStyles((theme) => createStyles({
  appBarNormal: {
    marginLeft: (props: CustomDrawerStylesProps) => props.widthClose,
    width: (props: CustomDrawerStylesProps) => `calc(100% - ${props.widthClose}px)`,
    // box-shadow is used for sticky header with duplicate title when main page title is invisible
    transition: theme.transitions.create(['width', 'margin', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: (props: CustomDrawerStylesProps) => props.widthOpen,
    width: (props: CustomDrawerStylesProps) => `calc(100% - ${props.widthOpen}px)`,
    transition: theme.transitions.create(['width', 'margin', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: 'green',
  },
  drawerOpen: {
    width: (props: CustomDrawerStylesProps) => props.widthOpen,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: (props: CustomDrawerStylesProps) => props.widthClose,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    border: 'none',
    backgroundPosition: 'left bottom, left bottom',
    backgroundRepeat: 'no-repeat, no-repeat',
  },
  drawerPaperSnow: {
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'cover, cover',
      backgroundImage: `url(${pictureSnow}), url(${gradientSnow})`,
    },
  },
  drawerPaperLandscaping: {
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain, cover',
      backgroundImage: `url(${pictureLandscaping}), url(${gradientLandscaping})`,
    },
  },
  drawerPaperSweeping: {
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain, cover',
      backgroundImage: `url(${pictureSweeping}), url(${gradientSweeping})`,
    },
  },
  drawerPaperWashing: {
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'cover, cover',
      backgroundImage: `url(${pictureWashing}), url(${gradientWashing})`,
    },
  },
  drawerPaperPestControl: {
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain, cover',
      backgroundImage: `url(${picturePestControl}), url(${gradientPestControl})`,
    },
  },
  drawerPaperShoppingCart: {
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'contain, cover',
      backgroundImage: `url(${pictureShoppingCart}), url(${gradientShoppingCart})`,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
    ...theme.mixins.toolbar,
  },
  toolbarSnow: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${topSnow})`,
    },
  },
  toolbarLandscaping: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${topLandscaping})`,
    },
  },
  toolbarSweeping: {
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'center top',
      backgroundImage: `url(${topSweeping})`,
    },
  },
  toolbarWashing: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${topWashing})`,
    },
  },
  customCollapseButton: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgba(8, 9, 60, 0.8)',
      '&:hover': {
        backgroundColor: 'rgba(8, 9, 60, 0.7)',
      },
    },
  },
  content: {
    flexGrow: 1,
  },
  logo: {
    width: 150,
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
  logoTop: {
    width: 125,
    margin: theme.spacing(0, 1),
    marginRight: 'auto',
  },
  appBarLogoChristmasIcon: {
    color: '#e53112',
    fontSize: '1em',
    position: 'absolute',
    left: '51px',
    top: '12px',
  },
  spacer: {
    flexGrow: 1,
  },
  menuList: {
    paddingTop: 0,
  },
}));

export interface SmartDrawerProps {
  navType?: NavType;
  widthOpen: number;
  onToggle?: (open: boolean) => void;
  appBar: (appBarClassName: string) => JSX.Element;
  menu: MenuListItemOrDivider[];
  mobileMenu?: MenuListItem[];
  desktop: boolean;
  hoverTimout?: number; // How long to wait before opening on mouse enter and closing on mouse leave
  fmServiceSlug?: string;
}

const isChatMenuItem = (item: MenuListItemOrDivider): item is MenuListItemUrl => typeof item === 'object'
    && item.type === '_menu_item_url'
    && [PATHS.fm.chat, PATHS.sp.chat].includes(item.url);

export const SmartDrawer: React.FC<SmartDrawerProps> = ({
  navType,
  widthOpen,
  onToggle,
  appBar,
  menu,
  mobileMenu,
  desktop,
  hoverTimout = 300,
  children,
  fmServiceSlug,
}) => {
  const history = useHistory();
  const profile = useProfile();
  const hasPermission = useUserPermissionChecker();
  const { unreadChannelsCount } = useChatUnreadCount();

  const isOrgOwner = hasPermission(Permissions.ORGANIZATION_OWNER);
  const isSubscribed = !!profile.organization?.subscription;
  const showSubscribeMenuItem = (!desktop && isOrgOwner && !isSubscribed && navType === 'sp');

  const fullMenu = useMemo(() => {
    const _menu = menu.map((item) => {
      if (isChatMenuItem(item) && unreadChannelsCount) {
        return {
          ...item,
          // Add unread dot badge for chat menu item
          badge: {
            variant: 'dot' as const,
            label: 'Unread messages',
          },
        };
      }
      return item;
    });

    if (showSubscribeMenuItem) {
      _menu.push({
        id: 'publish-profile',
        type: '_menu_item_url',
        icon: (
          <SvgIcon>
            <FontAwesomeIcon icon="lock-open-alt" />
          </SvgIcon>
        ),
        text: 'Subscribe',
        url: PATHS.sp.organizationInfo.billing,
      });
    }

    return _menu.filter((item) => (
      typeof item !== 'object' || !item.permission || hasPermission(item.permission)),
    );
  }, [menu, showSubscribeMenuItem, unreadChannelsCount, hasPermission]);

  const theme = useTheme();
  const widthClose = parseInt(theme.spacing(8), 10); // todo: check?
  const classes = useStyles({ widthOpen, widthClose });
  const downXxs = useMediaQuery('(max-width: 370px)');

  const [open, setOpen] = useState(getSmartDrawerOpen());
  const setOpenAndRemember = (value: boolean) => {
    setSmartDrawerOpen(value);
    setOpen(value);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [openOnHover, setOpenOnHover] = useState(false);
  const isOpen = open || openOnHover;

  useEffect(() => {
    // PROC-735: Open on hover only applies to desktop version
    if (desktop) {
      if (!isHovered) {
        const timeout = setTimeout(() => {
          setOpenOnHover(false);
        }, hoverTimout);

        return () => {
          clearTimeout(timeout);
        };
      }
      if (isHovered) {
        const timeout = setTimeout(() => {
          setOpenOnHover(true);
        }, hoverTimout);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
    return () => {};
  }, [desktop, hoverTimout, isHovered, openOnHover]);

  useEffect(() => {
    if (onToggle) onToggle(open || openOnHover);
  }, [onToggle, open, openOnHover]);

  const appBarClassName = isOpen ? classes.appBarShift : classes.appBarNormal;
  const drawerClassName = isOpen ? classes.drawerOpen : classes.drawerClose;
  const drawerPaperClassName = isOpen ? classes.drawerOpen : classes.drawerClose;

  let drawerPaperServiceClass = '';
  let toolbarServiceClass = '';
  let collapseButtonClass = '';
  const hasBrandedLogo = Boolean(profile.organization?.logo_url);

  let serviceValue = '';

  if (navType === 'sp') {
    serviceValue = document.location.hash;
  } else if (navType === 'fm') {
    serviceValue = fmServiceSlug ?? '';
  }

  if (serviceValue) {
    let isCustomCollapseButton = false;
    if (serviceValue.includes('snow-ice')) {
      drawerPaperServiceClass = classes.drawerPaperSnow;
      toolbarServiceClass = classes.toolbarSnow;
      isCustomCollapseButton = true;
    } else if (serviceValue.includes('landscaping')) {
      drawerPaperServiceClass = classes.drawerPaperLandscaping;
      toolbarServiceClass = classes.toolbarLandscaping;
      isCustomCollapseButton = true;
    } else if (serviceValue.includes('sweeping-and-portering')) {
      drawerPaperServiceClass = classes.drawerPaperSweeping;
      toolbarServiceClass = classes.toolbarSweeping;
      isCustomCollapseButton = true;
    } else if (serviceValue.includes('power-washing')) {
      drawerPaperServiceClass = classes.drawerPaperWashing;
      toolbarServiceClass = classes.toolbarWashing;
      isCustomCollapseButton = true;
    } else if (serviceValue.includes('pest-control')) {
      drawerPaperServiceClass = classes.drawerPaperPestControl;
      isCustomCollapseButton = true;
    } else if (serviceValue.includes('shopping-cart-retrieval')) {
      drawerPaperServiceClass = classes.drawerPaperShoppingCart;
      isCustomCollapseButton = true;
    }

    if (isCustomCollapseButton) {
      collapseButtonClass = classes.customCollapseButton;
    }

    if (hasBrandedLogo) {
      toolbarServiceClass = '';
    }
  }

  const { isChristmasEgg, appLogo } = useEasterEggs('white');

  return (
    <>
      {desktop && appBar(desktop ? appBarClassName : '')}
      <div className={desktop ? classes.wrapper : ''}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={drawerTheme}>
            <Drawer
              anchor={desktop ? 'left' : 'bottom'}
              variant="permanent"
              className={cn(
                classes.drawer,
                desktop && drawerClassName,
              )}
              classes={{
                paper: cn(
                  classes.drawerPaper,
                  drawerPaperServiceClass,
                  desktop && drawerPaperClassName,
                ),
              }}
            >
              <div className={cn(classes.toolbar, toolbarServiceClass)}>
                {isOpen && desktop && hasBrandedLogo && (
                  <>
                    {isChristmasEgg && (
                      <FontAwesomeIcon icon="hat-santa" className={classes.appBarLogoChristmasIcon} />
                    )}
                    <img src={appLogo} alt="Procursys logo" className={classes.logoTop} />
                  </>
                )}
                {!desktop && (
                  <>
                    <img src={logo} alt="Procursys logo" className={classes.logo} />
                    <div className={classes.spacer} />
                  </>
                )}
                {!desktop && mobileMenu && mobileMenu.map((item) => {
                  if (!item.icon) return null;
                  if (item.type === '_menu_item_url' || item.type === '_menu_item_onclick') {
                    return (
                      <MenuIconButton
                        key={item.id}
                        item={item}
                        size={downXxs ? 'small' : undefined}
                        toggleMenu={!desktop ? setOpenAndRemember : undefined}
                      />
                    );
                  }
                  return null;
                })}
                {isOpen ? (
                  <IconButton
                    className={collapseButtonClass}
                    onClick={() => setOpenAndRemember(false)}
                    aria-label="Collapse navigation"
                    title="Collapse navigation"
                    size="large"
                  >
                    <SvgIcon>
                      <FontAwesomeIcon icon={desktop ? 'arrow-from-right' : 'arrow-from-top'} />
                    </SvgIcon>
                  </IconButton>
                ) : (
                  <IconButton
                    className={collapseButtonClass}
                    onClick={() => setOpenAndRemember(true)}
                    aria-label="Expand navigation"
                    title="Expand navigation"
                    size="large"
                  >
                    <SvgIcon>
                      <FontAwesomeIcon icon={desktop ? 'arrow-to-right' : 'arrow-to-top'} />
                    </SvgIcon>
                  </IconButton>
                )}
              </div>
              <AnimateHeight
                height={
                // eslint-disable-next-line no-nested-ternary
                  desktop ? 'auto' : (open ? 'auto' : 0)
                }
                duration={
                  open
                    ? theme.transitions.duration.enteringScreen
                    : theme.transitions.duration.leavingScreen
                }
                easing={theme.transitions.easing.sharp}
              >
                <Divider />
                <List className={classes.menuList}>
                  {fullMenu.map((item, index) => {
                    if (item === 'divider') {
                    // eslint-disable-next-line react/no-array-index-key
                      return <Divider key={`divider-${index}`} />;
                    }
                    if (item.type === '_menu_item_url') {
                      const path = history.location.pathname.replace(/\/$/, '');
                      let selected = false;
                      if (item.pathMatchPrefix) {
                        selected = path.startsWith(item.pathMatchPrefix);
                      } else if (item.url) {
                        const link = item.url.replace(/\/$/, '');
                        selected = path.startsWith(link);
                      }
                      return (
                        <MenuItem
                          key={item.id}
                          item={item}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          selected={selected}
                          toggleMenu={!desktop ? setOpenAndRemember : undefined}
                        />
                      );
                    }
                    if (item.type === '_menu_item_onclick') {
                      return (
                        <MenuItem
                          key={item.id}
                          item={item}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        />
                      );
                    }
                    return (
                      <MenuItem
                        key={item.id}
                        item={item}
                      />
                    );
                  })}
                </List>
              </AnimateHeight>
            </Drawer>
          </ThemeProvider>
        </StyledEngineProvider>
        <main className={classes.content}>
          {children}
        </main>
      </div>
    </>
  );
};
