import { FieldValues } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { CustomFieldFormOptions, CustomFieldType } from './types';
import { FmSpFieldValueInput, OrganizationUpdateInput } from '../../api/types/globalTypes';
import { UpdateOrganizationVariables } from '../../api/types/UpdateOrganization';
import { UPDATE_ORGANIZATION } from '../../api/organization/updateOrganization';
import { MY_ORGANIZATION_QUERY } from '../../api/organization/myOrganization';
import { CustomFormTypes } from './const';
import { useProfile } from '../../api/user/profile';
import { Permissions } from '../../api/permissions/permissions';
import { UpdateFmSpFieldValueVariables } from '../../api/types/UpdateFmSpFieldValue';
import { UPDATE_FM_SP_FIELD_VALUE } from '../../api/fmSpFieldValues/updateFmSpFieldValue';
import { prepareValues } from './helpers';

interface UpdateOrganizationProps {
  targetOrganizationID: number;
  fieldValues: FieldValues;
  fieldArray: CustomFieldType[];
  serviceId: number | null;
}

interface UpdateFmSpFieldProps {
  targetOrganizationID: number;
  fieldValues: FieldValues;
  fieldArray: CustomFieldType[];
}

interface CustomFieldRequestProps {
  type: CustomFormTypes;
  targetOrganizationID: number;
  options?: CustomFieldFormOptions;
  fieldValues: FieldValues;
  fieldArray: CustomFieldType[];
}

/**
 * Check if we have permission to edit current form
 * For FmSPFieldValues it's not necessary, cause we show only current FM-user fields
 *
 * @param organization_id
 */
export const useServiceFormAccessDenied = (organization_id?: number) => {
  const profile = useProfile();

  const denied = useMemo(() => {
    const isOwner = !!profile.userPermissions
      .find(({ permission_id }) => (permission_id === Permissions.ORGANIZATION_OWNER));

    const allowed = isOwner && organization_id === profile.organization.organization_id;

    return !allowed;
  }, [organization_id, profile]);

  if (!organization_id) {
    return true;
  }

  return denied;
};

// TODO: move service fields update to separate backend service?
/**
 * Organization update specific methods
 * This happen when we try to update service fields values
 *
 * @param props
 */
const useUpdateOrganization = ({
  targetOrganizationID,
  fieldValues,
  fieldArray,
  serviceId,
}: UpdateOrganizationProps) => {
  const [
    organizationUpdate,
    {
      error,
      loading,
    },
  ] = useMutation<OrganizationUpdateInput, UpdateOrganizationVariables>(UPDATE_ORGANIZATION);

  const update = useCallback(async () => organizationUpdate({
    variables: {
      organization_id: targetOrganizationID,
      organization: {
        serviceFieldValues: prepareValues(fieldValues, fieldArray, serviceId),
      },
    },
    refetchQueries: [MY_ORGANIZATION_QUERY],
  }), [organizationUpdate, targetOrganizationID, fieldValues, fieldArray, serviceId]);

  return { update, error, loading };
};

/**
 * FmSpFieldValue update specific methods
 *
 * @param props
 */
const useUpdateFmSpField = ({
  targetOrganizationID,
  fieldValues,
  fieldArray,
}: UpdateFmSpFieldProps) => {
  const [
    fmSpValueUpdate,
    {
      error,
      loading,
    },
  ] = useMutation<FmSpFieldValueInput, UpdateFmSpFieldValueVariables>(UPDATE_FM_SP_FIELD_VALUE);

  const update = useCallback(async () => fmSpValueUpdate({
    variables: {
      organization_id: targetOrganizationID,
      values: prepareValues(fieldValues, fieldArray),
    },
  }), [fmSpValueUpdate, targetOrganizationID, fieldValues, fieldArray]);

  return { update, error, loading };
};

/**
 * Abstraction above specific field values update methods
 *
 * @param props
 */
export const useCustomFieldRequest = ({
  targetOrganizationID,
  options,
  type,
  fieldValues,
  fieldArray,
}: CustomFieldRequestProps) => {
  const orgAPI = useUpdateOrganization({
    targetOrganizationID,
    serviceId: get(options, 'serviceId', null),
    fieldValues,
    fieldArray,
  });

  const fmSpAPI = useUpdateFmSpField({
    targetOrganizationID,
    fieldValues,
    fieldArray,
  });

  const actions = {
    [CustomFormTypes.SERVICE]: {
      update: orgAPI.update,
      error: orgAPI.error,
      loading: orgAPI.loading,
    },
    [CustomFormTypes.FMSP]: {
      update: fmSpAPI.update,
      error: fmSpAPI.error,
      loading: fmSpAPI.loading,
    },
  };

  return actions[type];
};
